import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export enum ScreenSize {
    Desktop = 'desktop',
    Mobile = 'mobile',
    Tablet = 'tablet'
}
export interface ScreenSizeInfo {
    isMobile: boolean;
    isTablet: boolean;
    type: ScreenSize;
}

export function useScreenSize(): ScreenSizeInfo {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

    return {
        isMobile,
        isTablet,
        type: isTablet ? ScreenSize.Tablet : isMobile ? ScreenSize.Mobile : ScreenSize.Desktop
    };
}

export function useIsVisible(ref: React.RefObject<Element>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
        observer.observe(ref.current);
        // Unmount
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}

// Source: https://medium.com/the-non-traditional-developer/how-to-use-the-forwarded-ref-in-react-1fb108f4e6afs
export const useForwardedRef = <T>(ref: React.MutableRefObject<T> | React.RefCallback<T>) => {
    const innerRef = useRef(null);
    useEffect(() => {
        if (!ref) {
            return;
        }

        if (typeof ref === 'function') {
            ref(innerRef.current);
        } else {
            ref.current = innerRef.current;
        }
    });

    return innerRef;
};
