import React from 'react';

interface Props {
    html: string;
}

const FaqTab = (props: Props) => {
    return <div dangerouslySetInnerHTML={{ __html: props.html }} />;
};

export default FaqTab;
