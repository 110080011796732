import React from 'react';

function Exclamation() {
    return <span className="fa fa-exclamation-circle font-6 c-brand-warning-dark align-middle"></span>;
}

export default function IncompleteAddressWarning() {
    return (
        <div className="bg-brand-warning-light rounded-3 pad-y-3 pad-x-3 text-left">
            <div className="display-flex flex-wrap-nowrap">
                <div>
                    <Exclamation />
                </div>
                <div className="flex-1-1-0 mar-l-3">
                    <span className="display-inline-block c-brand-warning-dark font-4">
                        <strong>A complete street address is required to confirm plan availability.</strong> To view all
                        plans available at this general location, select &#39;Continue&#39;.
                    </span>
                </div>
            </div>
        </div>
    );
}
