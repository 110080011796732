import React from 'react';

import SliderWithLabel from '../../../../../../Shared/React/js/components/sliders/SliderWithLabel';
import type { SearchFilters } from '../../../../../js/@types/SearchFilters';

type SliderProps = SearchFilters['dataSlider'];

interface Props extends SliderProps {
    current: number;
    onChanged: (value: number) => void;
}

const DataFilter = (props: Props) => {
    return (
        <div className="hidden-xs col-sm-12">
            <div className="font-4 font-3-md c-gray-light line-height-11">{props.dataLabel}</div>
            <SliderWithLabel
                current={props.current}
                anyLabel={props.anyDataLabel}
                fromLabel={props.fromLabel}
                values={props.values}
                onValueChanged={props.onChanged}
            />
        </div>
    );
};

export default DataFilter;
