import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

import { initBootstrapSelect } from '../../../js/vendor/deferred/bootstrap-select';

export default class Select extends Component {
    constructor(props) {
        super(props);

        this.id = Select.counter++;
        this.elementId = this.props.elementId || `react-select-${this.id}`;

        this.state = {
            initiallySelected: JSON.stringify(this.props.selectedValues),
            rawSelectedValues: this.props.selectedValues
        };

        this.refSelect = createRef();
        this.getContainerDom = this.getContainerDom.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const initiallySelected = JSON.stringify(props.selectedValues);
        return initiallySelected !== state.initialAddress
            ? {
                initiallySelected: initiallySelected,
                rawSelectedValues: props.selectedValues
            }
            : null;
    }

    async componentDidMount() {
        await initBootstrapSelect();
        const select = wo$(this.refSelect.current);
        WhistleOut.applySelectPicker(select);
        WhistleOut.applySelectPickersStyle(this.getContainerDom());

        this.setSelectedValues(this.state.rawSelectedValues);
        select.on('change', this.onChange);
    }

    componentWillUnmount() {
        wo$(this.refSelect.current).off('change', this.onChange);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.disabled !== this.props.disabled) {
            wo$(this.refSelect.current).selectpicker('refresh');
            WhistleOut.applySelectPickersStyle(this.getContainerDom());
        }

        this.setSelectedValues(this.state.rawSelectedValues);
    }

    optionGroupsAreSame(a, b) {
        for (let i = 0; i < a.length; i++) {
            if (!a[i] || !b[i]) {
                return false;
            }

            return a[i] === b[i] || a[i].label === b[i].label || a[i].values === b[i].values;
        }
    }

    getContainerDom() {
        const bootStrapSelect = wo$(this.refSelect.current).prevUntil('div.bootstrap-select').parent();
        return bootStrapSelect.parent();
    }

    setSelectedValues(values) {
        wo$(this.refSelect.current).selectpicker('val', this.mapValues(values));
    }

    mapValues(values) {
        return Array.isArray(values) ? values.map(this.mapValue) : this.mapValue(values);
    }

    mapValue(value) {
        return value !== undefined && value !== null ? value.toString() : '';
    }

    onChange() {
        const selectedValues = wo$(this.refSelect.current).selectpicker('val');
        this.setState({
            rawSelectedValues: selectedValues
        });

        console.log('select.onChange:', selectedValues);

        if (this.props.onChange) {
            this.props.onChange(selectedValues);
        }
    }

    render() {
        const groups = this.props.optionGroups || [];
        const selectClassName = ClassNames(
            'selectpicker bs-select-hidden', //
            this.props.selectClassName
        );

        const renderItem = item => {
            return (
                <option title={item.title || item.value} key={item.key} value={item.key}>
                    {item.value}
                </option>
            );
        };

        return (
            <select
                ref={this.refSelect}
                id={this.elementId}
                tabIndex={this.props.tabIndex}
                className={selectClassName}
                multiple={this.props.multiple}
                data-selected-text-format="count>1"
                data-width={this.props.dataWidth}
                data-style={this.props.dataStyle}
                data-title={this.props.title}
                disabled={this.props.disabled}
            >
                {groups.map((group, index) => (
                    <optgroup key={`optgroup-${group.label || index}`} label={group.label}>
                        {group.values.map(renderItem)}
                    </optgroup>
                ))}
            </select>
        );
    }
}

/* eslint-disable import/no-named-as-default-member */
Select.propTypes = {
    elementId: PropTypes.string,
    optionGroups: PropTypes.arrayOf(PropTypes.object),
    selectedValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.bool)
    ]),
    // TODO: Rename to className
    selectClassName: PropTypes.string,
    tabIndex: PropTypes.number,
    hidden: PropTypes.bool,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    dataWidth: PropTypes.string,
    dataStyle: PropTypes.string,
    disabled: PropTypes.bool
};
/* eslint-enable */

Select.defaultProps = {
    tabIndex: -1,
    hidden: false,
    multiple: false,
    disabled: false
};

Select.counter = 0;
