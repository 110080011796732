import React, { useCallback, useMemo } from 'react';

import SearchAddressActions from '../../../../../../Shared/React/js/actions/SearchAddressActions';

function Title(props: { className: string }) {
    return <h4 className={props.className}>This provider is not available in your area</h4>;
}

function ViewPlansButton(props: { onClick(): void; className: string }) {
    return (
        <button type="button" className={props.className} onClick={props.onClick}>
            View Available Plans
        </button>
    );
}

function BackLink() {
    const handleClick = useCallback(() => {
        SearchAddressActions.setAddressCookie(null);
    }, []);

    return (
        <a href={window.location.href} onClick={handleClick}>
            {' '}
            <span className="fa fa-chevron-down" style={{ transform: 'rotate(90deg)' }}></span> Back to Address
            Verification{' '}
        </a>
    );
}

export default function SearchPlanUnavailablePanel(props: {
    isModal?: boolean;
    isSearchPage: boolean;
    onClose(): void;
}) {
    const onClose = useCallback(() => {
        if (props.onClose) {
            props.onClose();
        }
    }, [props]);

    const subtitle = props.isSearchPage
        ? 'Your search results will be updated to show available plans.'
        : 'Browse plans from providers that are available at your address.';

    const modalView = useMemo(() => {
        return (
            <>
                <Title className="font-feature font-8 font-800 line-height-105 c-gray-darker mar-b-3" />
                <p className="font-5 mar-b-5">{subtitle}</p>
                <ViewPlansButton className="btn btn-primary btn-lg" onClick={onClose} />
            </>
        );
    }, [onClose, subtitle]);

    const pageView = useMemo(() => {
        return (
            <>
                <Title className="font-feature font-9 font-800 line-height-12 c-gray-darker mar-b-3" />
                <p className="font-6 mar-b-4">{subtitle}</p>
                <ViewPlansButton className="btn btn-primary btn-lg mar-0 pad-x-8" onClick={onClose} />
                <hr />
                <BackLink />
            </>
        );
    }, [onClose, subtitle]);

    return (
        <div className="row">
            <div className="col-xs-24">{props.isModal ? modalView : pageView}</div>
        </div>
    );
}
