import React, { Component } from 'react';

import AddressSearch from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import BroadbandAddressSearchActions from './../../actions/BroadbandAddressSearchActions';

export default class BroadbandAddressSearch extends Component {

    constructor(props) {
        super(props);

        this.actions = new BroadbandAddressSearchActions(props.searchUrl, props.coverageUrl);
        this.onAddressChange = this.onAddressChange.bind(this);
    }

    onAddressChange(address) {
        if (this.props.ignoreAddressChange === true)
            return;
        let criteria = this.props.searchCriteria;
        if (!criteria) {
            criteria = { common: {} };
        }

        criteria.common.address = address;
        this.actions.onCriteriaChange(criteria);
    }

    render() {
        return (
            <React.StrictMode>
                <AddressSearch
                    className='form-group mar-b-0 text-left'
                    inputClassName="font-5"
                    countryCode={this.props.countryCode}
                    placeholder={this.props.text.addressSearchPlaceHolder}
                    types={this.props.types}
                    queryDelay={this.props.queryDelay}
                    apiKey={this.props.apiKey}
                    addressNotFoundMessage={this.props.text.unableToVerifyAddress}
                    commonErrorMessage={this.props.text.addressNotFound}
                    onChange={this.onAddressChange}
                    inputProps={{
                        size: 40
                    }}/>
            </React.StrictMode>
        );
    }
}
