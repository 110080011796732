import ClassNames from 'classnames';
import React, { ButtonHTMLAttributes, useCallback, useRef } from 'react';

interface props {
    buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
    children: JSX.Element;
    className?: string;
    selected: boolean;
    onSelected(selected: boolean): void;
}

const OptionButton = (props: props) => {
    const btnRef = useRef(null);

    const handleOnSelected = useCallback(() => {
        // Remove the focus to avoid staying highlighted on mouse out
        btnRef.current.blur();

        if (props.onSelected) {
            props.onSelected(!props.selected);
        }
    }, [props]);

    const className = ClassNames(
        props.className,
        'btn',
        { 'btn-info': !props.selected },
        { 'btn-default': props.selected === true },
        'btn-filter pad-0 mar-0 mar-r-2',
        { active: props.selected === true }
    );

    return (
        <button {...props.buttonProps} ref={btnRef} className={className} onClick={handleOnSelected}>
            {props.children}
        </button>
    );
};

export default OptionButton;
