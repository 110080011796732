import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSearchParams } from 'react-router-dom';

import type { Address } from '../../../../../Shared/js/@types/Address';
import { RootState } from '../../../../../Shared/js/redux/rootReducer';
import AppComponent from '../../../../../Shared/React/js/components/AppComponent';
import type { ProductPageModel } from '../../../../js/@types/ProductPageModel';
import { useProductLocation } from '../../hooks/location';
import { AvailabilityCheckModal, OnTransactUrlChangeArgs } from './availabilityCheck/AvailabilityCheckPanel';

const connector = connect((state: RootState, ownProps: ProductPageModel) => {
    return ownProps;
}, {});

export type Props = ConnectedProps<typeof connector>;

const ProductPageComponent = (props: Props) => {
    const location = useProductLocation();

    const handleTransactUrlChange = useCallback((args: OnTransactUrlChangeArgs) => {
        window.location.href = args.url;
    }, []);

    const handleShowPlans = useCallback(
        (address: Address) => {
            window.location.href = `${props.searchUrl}?${createSearchParams({ address: address?.label || '' })}`;
        },
        [props.searchUrl]
    );

    return (
        <div>
            <AvailabilityCheckModal
                address={props.addressSearch.address}
                productShortUrl={location.parts.product}
                supplierShortUrl={location.parts.supplier}
                countryCode={props.addressSearch.countryCode}
                addressSearchPlaceHolder={props.addressSearch.text.addressSearchPlaceHolder}
                tooltipLink={props.addressSearch.text.tooltipLink}
                tooltipDescription={props.addressSearch.text.tooltipDescription}
                queryDelay={props.addressSearch.queryDelay}
                apiKey={props.addressSearch.apiKey}
                onTransactUrlChange={handleTransactUrlChange}
                onShowPlans={handleShowPlans}
            />
        </div>
    );
};

const ProductPageConnected = connector(ProductPageComponent);

export default function ProductPage(props: Props) {
    return (
        <React.StrictMode>
            <AppComponent>
                <ProductPageConnected {...props} />
            </AppComponent>
        </React.StrictMode>
    );
}
