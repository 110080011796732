import defaultTo from 'lodash/defaultTo';
import parseInt from 'lodash/parseInt';
import React, { useCallback, useEffect, useRef } from 'react';

import ModalDataSlider from './ModalDataSlider';

const DataTab = ({
    currentData,
    values,
    peakDataOnly,
    includedDataLabel,
    anyDataLabel,
    atLeastLabel,
    peakDataCheckboxText,
    peakDataTooltipText,
    buttonsContentFragment,
    changeData,
    changePeakDataOnly
}) => {
    const buttonsContainerRef = useRef(null);

    const handleOnMount = useCallback(() => {
        const buttonsContainer = wo$(buttonsContainerRef.current);
        buttonsContainer
            .find('[data-min-data]')
            .off('click')
            .click(e => {
                const value = defaultTo(parseInt(e.currentTarget.dataset.minData), null);
                changeData(value);
            });
    }, [changeData]);

    useEffect(() => handleOnMount(), [handleOnMount]);

    return (
        <>
            <div className="row pad-t-2 mar-b-3">
                <div className="col-md-24">
                    <h5 className="font-6 mar-0">{includedDataLabel}</h5>
                </div>
            </div>
            <div className="row sep-b-1 mar-y-5 pad-b-6">
                <div className="col-md-20 col-md-offset-2">
                    <ModalDataSlider
                        current={currentData}
                        values={values}
                        peakDataOnly={peakDataOnly}
                        anyDataLabel={anyDataLabel}
                        atLeastLabel={atLeastLabel}
                        peakDataCheckboxText={peakDataCheckboxText}
                        peakDataTooltipText={peakDataTooltipText}
                        changeData={changeData}
                        changePeakDataOnly={changePeakDataOnly}
                    />
                </div>
            </div>
            <div
                ref={buttonsContainerRef}
                className="row mar-b-5"
                dangerouslySetInnerHTML={{ __html: buttonsContentFragment }}
            />
        </>
    );
};

export default DataTab;
