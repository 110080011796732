import React from 'react';

import type { Supplier } from '../../../../../../Shared/js/@types/Supplier';
import type { SuppliersModel } from '../../../../../../Shared/js/@types/SuppliersModel';
import SuppliersOptionGroup from '../../../../../../Shared/React/js/components/optionButtons/SuppliersOptionGroup';

interface Props {
    isBusinessOnly: boolean;
    selectedKeys: string[];
    showTextOnly: boolean;
    textContent: string;
    showHeader: boolean;
    suppliers: SuppliersModel;
    getKey: (item: Supplier) => string;
    onChanged: (keys: string[]) => void;
    onShowAll: () => void;
}

const SuppliersFilter = (props: Props) => {
    return (
        <div className="hidden-xs col-sm-19 col-md-12 col-lg-12 filters-1">
            {props.showTextOnly ? (
                <div dangerouslySetInnerHTML={{ __html: props.textContent }}></div>
            ) : (
                <SuppliersOptionGroup
                    {...props.suppliers}
                    selectedKeys={props.selectedKeys}
                    businessOnly={props.isBusinessOnly}
                    showHeader={props.showHeader}
                    onSelectedItemsChanged={props.onChanged}
                    onShowAllProviders={props.onShowAll}
                    getKey={props.getKey}
                />
            )}
        </div>
    );
};

export default SuppliersFilter;
