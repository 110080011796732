import React from 'react';

import { AddressSearchProps } from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import type { HomeModel } from '../../../../js/@types/HomeModel';
import Filters from './Filters';

const Home = ({ addressSearch, ...props }: HomeModel) => {
    const criteria = props.state.criteria;
    return (
        <React.StrictMode>
            <React.Fragment>
                <Filters
                    address={criteria.common.address}
                    speed={criteria.speed}
                    data={criteria.data}
                    criteria={criteria}
                    addressSearchProps={
                        {
                            countryCode: addressSearch.countryCode,
                            placeholder: addressSearch.text.addressSearchPlaceHolder,
                            tooltipLink: addressSearch.text.tooltipLink,
                            tooltipDescription: addressSearch.text.tooltipDescription,
                            addressNotFoundMessage: addressSearch.text.addressNotFound,
                            commonErrorMessage: addressSearch.text.unableToVerifyAddress,
                            types: addressSearch.types,
                            queryDelay: addressSearch.queryDelay,
                            apiKey: addressSearch.apiKey,
                            showProgress: false
                        } as AddressSearchProps
                    }
                    speedDataSelectProps={{
                        primaryFilterAsSpeed: props.primaryFilterAsSpeed,

                        speedDescription: props.speed.description,
                        speedFormat: props.speed.format,
                        speedValues: props.speed.values,

                        dataDescription: props.data.description,
                        dataFormat: props.data.format,
                        dataValues: props.data.values
                    }}
                    error={addressSearch.text.unableToVerifyAddress}
                    warning={addressSearch.text.addressNotFound}
                    searchButtonText={props.searchText}
                    coverageCheckUrl={props.coverageUrl}
                    searchUrl={props.searchUrl}
                    splashImageUrl={props.splashImageUrl}
                    marginCss={props.marginCss}
                />
            </React.Fragment>
        </React.StrictMode>
    );
};

export default Home;
