import React from 'react';

import OptionButton from './OptionButton';

const OptionImageButton = (props: {
    className: string;
    imageAlt: string;
    imageSrc: string;
    selected: boolean;
    onSelected(selected: boolean): void;
}) => {
    return (
        <OptionButton
            selected={props.selected}
            onSelected={props.onSelected}
            className={props.className}
            buttonProps={{
                'data-toggle': 'popover',
                'data-trigger': 'hover',
                'data-placement': 'auto',
                'data-animation': true,
                'data-content': props.imageAlt
            }}
        >
            <img className="img-responsive center-block brand-equalizer" src={props.imageSrc} alt={props.imageAlt} />
        </OptionButton>
    );
};

export default OptionImageButton;
