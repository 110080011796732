import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SupplierListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rawChecked: null
        };

        this.onCheckedChanged = this.onCheckedChanged.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return props.checked === state.rawChecked
            ? { rawChecked: null }
            : null;
    }

    onCheckedChanged() {
        const current = this.state.rawChecked !== null
            ? this.state.rawChecked
            : this.props.checked;

        const checked = !current;
        this.setState({ checked: checked });

        if (this.props.onCheckedChanged) {
            this.props.onCheckedChanged(this.props.supplierId, checked);
        }
    }

    render() {
        const checked = this.state.rawChecked !== null
            ? this.state.rawChecked
            : this.props.checked;

        return (
            <li key={this.props.supplierId}>
                <div className="checkbox-tick font-4">
                    <input
                        type="checkbox"
                        id={this.props.supplierId}
                        checked={checked}
                        onChange={this.onCheckedChanged}
                        disabled={this.props.disabled}
                    />
                    <label className="font-300" htmlFor={this.props.supplierId}>
                        <span>
                            {this.props.name}
                        </span>
                    </label>
                </div>
            </li>
        );
    }
}

/* eslint-disable import/no-named-as-default-member */
SupplierListItem.propTypes = {
    supplierId: PropTypes.number,
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
};
/* eslint-enable */
