import React, { useCallback } from 'react';

import AddressSearch from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import { ScreenSize } from '../../../../../Shared/React/js/utils/Hooks';
import type { Props } from './@types/Filters';
import SearchButton from './SearchButton';
import SpeedDataSelect from './SpeedDataSelect';

const screenSize: ScreenSize = ScreenSize.Mobile;

const FiltersXs = (props: Props) => {
    const onSearchClick = useCallback(() => props.onSearchClick(screenSize), [props]);

    const renderSplashImage = () => {
        if (props.splashImageUrl && props.splashImageUrl.length > 0) {
            return <img className="img-responsive center-block mar-t-7 pad-t-4" src={props.splashImageUrl} alt="Supplier logos" />;
        }
    };

    return (
        <>
            <div className="pad-t-5 pad-b-3-xs pad-b-5-sm">
                <div className="row">
                    <div className="col-sm-14 col-sm-offset-1">
                        <div className="visible-xs">
                            <AddressSearch
                                {...props.addressSearchProps}
                                ref={props.addressSearchProps.ref}
                                className={`form-group ${props.marginCss}`}
                                inputClassName="font-5 input-lg"
                            />
                            <div className="form-group">
                                <SpeedDataSelect {...props.speedDataSelectProps} screenSize={screenSize} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pad-b-6">
                <div className="col-xs-24">
                    <SearchButton
                        className="btn btn-lg btn-primary btn-block"
                        text={props.searchButtonText}
                        isLoading={props.isLoading}
                        onClick={onSearchClick}
                    />
                    
                    {renderSplashImage()}                                               
                </div>
            </div>
        </>
    );
};

export default FiltersXs;
