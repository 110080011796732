import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import type { Address } from '../../../../../../Shared/js/@types/Address';
import { AddressSearchProps } from '../../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import Modal from '../../../../../../Shared/React/js/components/Modal';
import StatusLayout from '../../../../../../Shared/React/js/components/StatusLayout';
import TransactLinkHandler, {
    DynamicTransactLinkData
} from '../../../../../../Shared/React/js/components/TransactLinkHandler';
import { broadbandApi } from '../../../../../js/redux/broadbandApi';
import AddressSearchPanel from './AddressSearchPanel';
import ErrorPanel from './ErrorPanel';
import PopupBlockerMessage from './PopupBlockerMessage';
import SearchPlanUnavailablePanel from './SearchPlanUnavailablePanel';

const addressHelper = {
    isIncomplete: (address: Address) => !address || !address.streetNumber,
    isIncompleteLabel(address: string) {
        return !/^\s*?\d+\s+.+?\s+\d+\s*?$/.test(address);
    },
    getInitialAddress: (props: Address) => (addressHelper.isIncomplete(props) ? null : props)
};

const browserHelper = {
    isBlocked: (newWin: Window) => {
        return !newWin || newWin.closed || typeof newWin.closed === 'undefined';
    }
};

const urlHelper = {
    getAddress(href: string) {
        return new URL(href).searchParams.get('address')?.replace(/\+/g, ' ');
    }
};

export interface OnTransactUrlChangeArgs {
    url: string;
    address: Address;
}

type Status = 'initialised' | 'request' | 'in progress' | 'found' | 'blocked' | 'not found' | 'error';

interface State {
    status: Status;
    address: Address;
    transactUrl: string;
    isLoading: boolean;
}

const { actions, reducer } = createSlice({
    name: 'address-autocomplete',
    initialState: {
        status: 'initialised',
        address: null,
        transactUrl: null,
        isLoading: false
    } as State,
    reducers: {
        requestSearch(state, action: PayloadAction<Address>) {
            state.status = 'request';
            state.address = action.payload;
            state.isLoading = false;
        },
        setAddress(state, action: PayloadAction<Address>) {
            state.address = action.payload;
        },
        startProgress(state) {
            state.isLoading = true;
        },
        endProgress(state) {
            state.isLoading = false;
        },
        onError(state) {
            state.status = 'error';
            state.isLoading = false;
        },
        onResponse(
            state,
            action: PayloadAction<{
                transactUrl: string;
            }>
        ) {
            const { transactUrl } = action.payload;

            state.status = transactUrl ? 'found' : 'not found';
            state.transactUrl = transactUrl;
            state.isLoading = false;
        },
        onTransactUrlFound(state) {
            state.status = 'initialised';
            state.isLoading = false;
        },
        onPopupBlocked(state) {
            state.status = 'blocked';
            state.isLoading = false;
        }
    }
});

interface Props {
    address: Address;
    productShortUrl?: string;
    supplierShortUrl?: string;
    countryCode: string;
    addressSearchPlaceHolder: string;
    tooltipLink: string;
    tooltipDescription: string;
    queryDelay: number;
    apiKey: string;
    isModal?: boolean;
    isSearchPage?: boolean;
    shouldOpenTransactLink?: boolean;
    autoSubmitOnLoad?: boolean;
    onAddressChange?(address: Address): void;
    onTransactUrlChange(args: OnTransactUrlChangeArgs): void;
    onTransactLinkClick?(): void;
    onShowPlans(address: Address): void;
    onStatusChange?(status: Status): void;
    onProgressChange?(isBusy: boolean): void;
}

export const AvailabilityCheckPanel = (props: Props) => {
    const [state, dispatch] = useReducer(reducer, {
        status: 'initialised',
        address: addressHelper.getInitialAddress(props.address),
        transactUrl: undefined as string,
        isLoading: false
    });

    const [triggerGetTransactUrlQuery, getTransactUrlQuery] = broadbandApi.useLazyGetTransactUrlQuery();

    useEffect(() => dispatch(actions.setAddress(addressHelper.getInitialAddress(props.address))), [props.address]);

    useEffect(() => {
        if (props.onAddressChange) {
            props.onAddressChange(state.address);
        }
    }, [props, state.address]);

    useEffect(() => {
        if (props.onStatusChange) {
            props.onStatusChange(state.status);
        }
    }, [props, state.status]);

    const handleContinueClick = useCallback((address: Address) => {
        dispatch(actions.requestSearch(address));
    }, []);

    const handleShowPlans = useCallback(() => {
        if (state.isLoading) {
            return;
        }
        dispatch(actions.startProgress());
        props.onShowPlans(state.address);
    }, [props, state.address, state.isLoading]);

    const handleRetry = useCallback(() => {
        dispatch(actions.requestSearch(state.address));
    }, [state.address]);

    useEffect(() => {
        if (getTransactUrlQuery.isLoading) {
            dispatch(actions.startProgress());
        }
    }, [getTransactUrlQuery.isLoading]);

    useEffect(() => {
        if (props.onProgressChange) {
            props.onProgressChange(state.isLoading);
        }
        return () => {
            if (props.onProgressChange) {
                props.onProgressChange(false);
            }
        };
    }, [props, state.isLoading]);

    const isIncompleteAddress = useMemo(() => addressHelper.isIncomplete(state.address), [state.address]);

    useEffect(() => {
        const onRequest = () => {
            if (state.status !== 'request' || getTransactUrlQuery.isLoading) {
                return;
            }

            if (isIncompleteAddress || !props.supplierShortUrl) {
                handleShowPlans();
                return;
            }

            triggerGetTransactUrlQuery({
                productShortUrl: props.productShortUrl,
                supplierShortUrl: props.supplierShortUrl,
                address: state.address?.label
            });
        };
        onRequest();
    }, [
        getTransactUrlQuery.isLoading,
        handleShowPlans,
        isIncompleteAddress,
        props.productShortUrl,
        props.supplierShortUrl,
        state.address?.label,
        state.status,
        triggerGetTransactUrlQuery
    ]);

    useEffect(() => {
        const onResponse = () => {
            if (getTransactUrlQuery.isUninitialized || getTransactUrlQuery.isLoading) {
                return;
            }

            const transactUrl = getTransactUrlQuery.data?.transactUrl;
            dispatch(actions.onResponse({ transactUrl }));
        };
        onResponse();
    }, [getTransactUrlQuery.data?.transactUrl, getTransactUrlQuery.isLoading, getTransactUrlQuery.isUninitialized]);

    useEffect(() => {
        if (state.isLoading || state.status !== 'found') {
            return;
        }

        const openTransactUrl = () => {
            const transactPage = window.open(state.transactUrl);
            if (!browserHelper.isBlocked(transactPage)) {
                if (props.onTransactLinkClick) {
                    props.onTransactLinkClick();
                }
                transactPage.focus();
                handleShowPlans();
            } else {
                dispatch(actions.onPopupBlocked());
            }
        };

        const onTransactUrlFound = () => {
            dispatch(actions.onTransactUrlFound());

            if (props.onTransactUrlChange) {
                props.onTransactUrlChange({
                    url: state.transactUrl,
                    address: state.address
                });
            }

            if (!props.shouldOpenTransactLink) {
                return;
            }

            openTransactUrl();
        };

        onTransactUrlFound();
    }, [handleShowPlans, props, state.address, state.isLoading, state.status, state.transactUrl]);

    useEffect(() => {
        if (getTransactUrlQuery.isError) {
            dispatch(actions.onError());
        }
    }, [getTransactUrlQuery.isError]);

    useEffect(() => {
        if (props.autoSubmitOnLoad && !isIncompleteAddress) {
            handleContinueClick(state.address);
        }
    }, [handleContinueClick, isIncompleteAddress, props.autoSubmitOnLoad, state.address]);

    return (
        <>
            <StatusLayout<Status>
                status={state.status}
                items={{
                    default: (
                        <AddressSearchPanel
                            isLoading={state.isLoading}
                            isModal={props.isModal}
                            onContinue={handleContinueClick}
                            addressSearchProps={
                                {
                                    current: state.address,
                                    countryCode: props.countryCode,
                                    placeholder: props.addressSearchPlaceHolder,
                                    tooltipLink: <i>{props.tooltipLink}</i>,
                                    tooltipDescription: props.tooltipDescription,
                                    types: ['Geo,PAD,Str,Addr'],
                                    queryDelay: props.queryDelay,
                                    apiKey: props.apiKey
                                } as AddressSearchProps
                            }
                        />
                    ),
                    error: <ErrorPanel onRetry={handleRetry} isLoading={state.isLoading} />,
                    blocked: (
                        <PopupBlockerMessage
                            isModal={props.isModal}
                            url={state.transactUrl}
                            onClick={props.onTransactLinkClick}
                        />
                    ),
                    'not found': (
                        <SearchPlanUnavailablePanel
                            onClose={handleShowPlans}
                            isModal={props.isModal}
                            isSearchPage={props.isSearchPage}
                        />
                    )
                }}
            />
        </>
    );
};

export const AvailabilityCheckModal = (props: Props) => {
    const [address, setAddress] = useState(props.address);
    const [isOpen, setIsOpen] = useState(false);
    const [productShortUrl, setProductShortUrl] = useState(props.productShortUrl);
    const [supplierShortUrl, setSupplierShortUrl] = useState(props.supplierShortUrl);
    const [status, setStatus] = useState<Status>();

    const handleClose = useCallback(() => {
        setIsOpen(false);
        const showPlansOnStatuses: Status[] = ['found', 'not found', 'blocked'];
        if (showPlansOnStatuses.includes(status)) {
            props.onShowPlans(address);
        }
    }, [address, props, status]);

    const isDynamicTransactLink = useCallback((href: string) => {
        return (
            /\/smartmove-internet-verification-/i.test(href) ||
            (/\/SmartMoveVerify/i.test(href) && addressHelper.isIncompleteLabel(urlHelper.getAddress(href)))
        );
    }, []);

    const handleDynamicTransactLinkClick = useCallback(
        (e: DynamicTransactLinkData) => {
            setIsOpen(true);
            setProductShortUrl(e.productShortUrl || props.productShortUrl);
            setSupplierShortUrl(e.supplierShortUrl || props.supplierShortUrl);
        },
        [props.productShortUrl, props.supplierShortUrl]
    );

    const handleTransactUrlChange = useCallback(
        (args: OnTransactUrlChangeArgs) => {
            if (!props.shouldOpenTransactLink) {
                setIsOpen(false);
            }
            if (props.onTransactUrlChange) {
                props.onTransactUrlChange(args);
            }
        },
        [props]
    );

    const handleShowPlans = useCallback(
        (address: Address) => {
            setIsOpen(false);
            if (props.onShowPlans) {
                props.onShowPlans(address);
            }
        },
        [props]
    );

    const handleTransactLinkClick = useCallback(() => {
        handleClose();
        if (props.onTransactLinkClick) {
            props.onTransactLinkClick();
        }
    }, [handleClose, props]);

    useEffect(() => setProductShortUrl(props.productShortUrl), [props.productShortUrl]);
    useEffect(() => setSupplierShortUrl(props.supplierShortUrl), [props.supplierShortUrl]);

    return (
        <>
            <TransactLinkHandler
                isDynamicLink={isDynamicTransactLink}
                onDynamicLinkClick={handleDynamicTransactLinkClick}
            />
            {isOpen && (
                <Modal isOpen={isOpen} onClose={handleClose}>
                    <AvailabilityCheckPanel
                        {...props}
                        isModal
                        productShortUrl={productShortUrl}
                        supplierShortUrl={supplierShortUrl}
                        onAddressChange={setAddress}
                        onShowPlans={handleShowPlans}
                        onStatusChange={setStatus}
                        onTransactUrlChange={handleTransactUrlChange}
                        onTransactLinkClick={handleTransactLinkClick}
                    />
                </Modal>
            )}
        </>
    );
};
