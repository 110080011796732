import React, { useMemo } from 'react';

// See: https://stackoverflow.com/questions/10471595/window-open-blocked-by-firefox

const ContinueButton = (props: { className: string; url: string; onClick(): void }) => {
    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={props.url} target="_blank" className={props.className} onClick={props.onClick}>
            Continue
        </a>
    );
};

const title = (
    <>
        It looks like you’re using a <span className="text-nowrap">pop-up</span> blocker
    </>
);
const subtitle = <>Select ‘Continue’ to open the plan in a new window.</>;

const PopupBlockerMessage = (props: { isModal: boolean; url: string; onClick(): void }) => {
    const pageView = useMemo(() => {
        return (
            <>
                <h4 className="font-feature font-9 font-800 line-height-12 c-gray-darker mar-b-3">{title}</h4>
                <p className="font-5">{subtitle}</p>
                <ContinueButton
                    url={props.url}
                    className="btn btn-primary btn-lg mar-y-5 pad-x-8"
                    onClick={props.onClick}
                />
            </>
        );
    }, [props.onClick, props.url]);

    const modalView = useMemo(() => {
        return (
            <>
                <h4 className="font-feature font-8 font-800 line-height-105 c-gray-darker mar-b-5">{title}</h4>
                <p className="font-5">{subtitle}</p>
                <ContinueButton url={props.url} className="btn btn-primary btn-lg" onClick={props.onClick} />
            </>
        );
    }, [props.onClick, props.url]);

    return <div className="row">{props.isModal ? modalView : pageView}</div>;
};

export default PopupBlockerMessage;
