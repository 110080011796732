import React, { useCallback, useEffect, useRef } from 'react';

import SliderWithLabel from '../../../../../../Shared/React/js/components/sliders/SliderWithLabel';

const ModalDataSlider = ({
    current,
    values,
    peakDataOnly,
    anyDataLabel,
    atLeastLabel,
    peakDataCheckboxText,
    peakDataTooltipText,
    changeData,
    changePeakDataOnly
}) => {
    const popoverRef = useRef(null);

    useEffect(() => {
        const popover = wo$(popoverRef.current);
        WhistleOut.applyPopover(popover);
    }, []);

    const handlePeakDataValueChanged = useCallback(
        e => {
            changePeakDataOnly(e.currentTarget.checked);
        },
        [changePeakDataOnly]
    );

    const getLabel = useCallback(labelText => <strong className="font-5">{labelText}</strong>, []);

    return (
        <>
            <SliderWithLabel
                current={current}
                values={values}
                anyLabel={anyDataLabel}
                fromLabel={atLeastLabel}
                containerClassName="pad-t-4"
                labelTemplate={getLabel}
                sliderClassName="rc-slider-container mar-y-2"
                onValueChanged={changeData}
            />
            <div ref={popoverRef} className="checkbox-tick pad-t-2 font-2-xs">
                <input type="checkbox" id="peakcheckbox" checked={peakDataOnly} onChange={handlePeakDataValueChanged} />
                <label htmlFor="peakcheckbox" className="font-400 c-gray-light">
                    {peakDataCheckboxText}
                </label>
                &nbsp;
                <span
                    className="fa fa-info-circle hidden-xs c-gray-light hover"
                    data-container="body"
                    data-toggle="popover"
                    data-trigger="click"
                    data-placement="top"
                    data-content={peakDataTooltipText}
                />
            </div>
        </>
    );
};

export default ModalDataSlider;
