const defaults = {
    featuredResultLocationType: 5 // search results
};

// TODO: Try reducing cyclomatic complexity and enable the eslint rule
// eslint-disable-next-line complexity
export function generateCommonQueryString(
    /**@type {import('../../../js/@types/SearchCriteriaCommon').SearchCriteriaCommon} */ common,
    /** @type {boolean} */ isEmbed
) {
    const appendQueryParam = WhistleOut.appendQueryParam;
    let queryString = '';
    let config = WhistleOut.getSiteConfiguration();

    if (common.tab && common.tab !== 'all') {
        queryString = appendQueryParam(queryString, 'tab=' + common.tab);
    }

    if (common.resultsSortExpression && common.resultsSortExpression !== 'Price') {
        queryString = appendQueryParam(queryString, 'sort=' + common.resultsSortExpression);
    }

    if (common.resultsSortAscending === false) {
        queryString = appendQueryParam(queryString, 'sortascending=false');
    }

    if (common.includeFeaturedResults === false) {
        queryString = appendQueryParam(queryString, 'includefeatured=false');
    }

    if (
        isEmbed &&
        common.featuredResultLocationType !== defaults.featuredResultLocationType &&
        WhistleOut.isPositiveNumber(common.featuredResultLocationType)
    ) {
        queryString = appendQueryParam(queryString, `featuredresultlocationtype=${common.featuredResultLocationType}`);
    }

    if (common.includeOffersWithCampaignOnly === true || common.includeOffersWithCampaignOnly === 'true') {
        queryString = appendQueryParam(queryString, 'dealsonly=true');
    }

    if (common.enforceMaximumResultLimits === false) {
        queryString = appendQueryParam(queryString, 'enforcemaximumresultlimits=false');
    }

    if (
        common.maximumSpend &&
        WhistleOut.isPositiveNumber(common.maximumSpend) &&
        common.maximumSpend !== config.broadbandConfig.maxSpend
    ) {
        queryString = appendQueryParam(queryString, 'spend=' + common.maximumSpend);
    }

    if (common.minimumSpend && WhistleOut.isPositiveNumber(common.minimumSpend)) {
        queryString = appendQueryParam(queryString, 'minspend=' + common.minimumSpend);
    }

    if (WhistleOut.isPositiveNumber(common.maximumUpfront)) {
        queryString = appendQueryParam(queryString, 'upfront=' + common.maximumUpfront);
    }

    if (common.contractTerms && common.contractTerms.length > 0) {
        queryString = appendQueryParam(queryString, 'contract=' + common.contractTerms.join(','));
    }

    if (common.address && common.address.label) {
        const address = replaceSpaces(common.address.label);
        queryString = appendQueryParam(queryString, 'address=' + address);
    }

    if (common.suppliers && common.suppliers.values && common.suppliers.values.length > 0) {
        queryString = appendQueryParam(queryString, 'supplier=' + common.suppliers.values.join(','));
    }

    if (common.customerType && common.customerType.length > 0) {
        queryString = appendQueryParam(queryString, 'customer=' + common.customerType);
    }

    if (common.currentSupplierShortUrl && common.currentSupplierShortUrl !== '-None-') {
        queryString = appendQueryParam(queryString, 'currentsupplier=' + common.currentSupplierShortUrl);
    }

    if (common.showLessResults) {
        queryString = appendQueryParam(queryString, 'showlessresults=true');
    }

    if (common.debug === true || common.debug === 'true') {
        queryString = appendQueryParam(queryString, 'debug=true');
    }

    if (common.useCache !== undefined && !common.useCache) {
        queryString = appendQueryParam(queryString, 'cache=false');
    }

    if (common.maxResultsPerSupplier && common.maxResultsPerSupplier > 0) {
        queryString = appendQueryParam(queryString, 'maxresultspersupplier=' + common.maxResultsPerSupplier);
    }

    if (common.products && common.products.length > 0) {
        queryString = appendQueryParam(queryString, 'product=' + common.products.join(','));
    }

    if (common.campaignGroup && common.campaignGroup.length > 0) {
        queryString = appendQueryParam(queryString, 'campaignGroup=' + common.campaignGroup);
    }

    if (common.results) {
        const resultQueryString = generateResultsQueryString(common.results);
        if (resultQueryString.length > 0) {
            queryString = appendQueryParam(queryString, resultQueryString);
        }
    }
    return queryString;
}

const generateResultsQueryString = results => {
    const appendQueryParam = WhistleOut.appendQueryParam;
    let queryString = '';

    if (results.maximumNumberOfResults && results.maximumNumberOfResults > 0) {
        queryString = appendQueryParam(queryString, 'maxresults=' + results.maximumNumberOfResults);
    }
    if (results.hideTransactionLinks) {
        queryString = appendQueryParam(queryString, 'transact=false');
    }
    if (results.showRowFilters === false) {
        queryString = appendQueryParam(queryString, 'showrowfilters=false');
    }
    if (results.showRowSorts === false) {
        queryString = appendQueryParam(queryString, 'showrowsorts=false');
    }
    if (results.hideEmptyTabs === true) {
        queryString = appendQueryParam(queryString, 'hideemptytabs=true');
    }
    if (results.hideSort) {
        queryString = appendQueryParam(queryString, 'hidesort=true');
    }
    if (results.hideShare) {
        queryString = appendQueryParam(queryString, 'hideshare=true');
    }
    if (results.hideWidenResults) {
        queryString = appendQueryParam(queryString, 'hidewidenresults=true');
    }
    if (results.showAllResults) {
        queryString = appendQueryParam(queryString, 'showall=true');
    }
    if (results.hideCoverage) {
        queryString = appendQueryParam(queryString, 'hidecoverage=true');
    }
    if (results.productLabel) {
        queryString = appendQueryParam(queryString, 'productlabel=' + encodeURIComponent(results.productLabel));
    }
    if (results.monetisedResultsOnly) {
        queryString = appendQueryParam(queryString, 'monetised=true');
    }
    if (results.pageSize && results.pageSize > 0 && results.pageSize !== results.defaultPageSizeConstant) {
        queryString = appendQueryParam(queryString, 'pagesize=' + results.pageSize);
    }
    return queryString;
};

const replaceSpaces = (input, replaceWith = '+') => {
    if (!input) {
        return null;
    }

    return input.replace(/\s+/g, replaceWith);
};
