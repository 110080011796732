import '../../../../Shared/js/SpeedTest';

import toNumber from 'lodash/toNumber';

import { waitFor } from '../../../../Shared/js/utils/timer';
import { generateQueryString } from '../utils/BroadbandQueryString';

export default class BroadbandResultsAdaptor {
    unbindAll() {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;
        searchResultsPage.off('click');
        this.bindPreviewActions();
    }

    async bindCoverageMap() {
        const searchResultsPage = wo$('#results-container');
        if (!searchResultsPage.length) return;

        const mapContainer = searchResultsPage.find('[data-coverage-maps]');
        if (!mapContainer.length) {
            return;
        }

        await waitFor(() => !!WhistleOut.CoverageMap);
        if (WhistleOut.CoverageMap) {
            WhistleOut.CoverageMap.init(searchResultsPage);
        }

        var instanceId = mapContainer.data('instance-id');

        const nbnMapElement = searchResultsPage.find('#nbn-map-' + instanceId);
        const adslMapElement = searchResultsPage.find('#adsl-map-' + instanceId);
        const mobileMapElement = searchResultsPage.find('#mobile-map-' + instanceId);

        const coverageMapsButton = wo$('#coverage-maps-button');
        if (coverageMapsButton.length) {
            if (nbnMapElement.length || adslMapElement.length || mobileMapElement.length) {
                coverageMapsButton.removeClass('hidden');
            } else {
                coverageMapsButton.addClass('hidden');
            }

            coverageMapsButton.find('[data-goto-coverage-map]').click(function () {
                WhistleOut.scrollTo('[data-coverage-maps]');
            });
        }
    }

    bindFastProviders() {
        const fastProviders = wo$('#fast-providers');
        if (!fastProviders) return;
        var close = fastProviders.find('#fast-providers-close');
        close.off('click').on('click', function () {
            var expires = new Date();
            expires.setTime(expires.getTime() + 60 * 60 * 1000);
            Cookies.set('HideFastProviders', '1', { path: '/', expires: expires });
            fastProviders.slideUp();
        });
    }

    hideCoverageMapButton() {
        const coverageMapsButton = wo$('#coverage-maps-button');
        if (coverageMapsButton.length) {
            coverageMapsButton.addClass('hidden');
        }
    }

    bindContractDropdown(changeContractTypeHandler, showModalHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage
            .off('click', '[data-show-only-contract]')
            .on('click', '[data-show-only-contract]', function () {
                const contract = wo$(this).data('show-only-contract');
                changeContractTypeHandler(contract);
            });

        searchResultsPage //
            .off('click', '[data-hide-all-contract]')
            .on('click', '[data-hide-all-contract]', function () {
                const contract = wo$(this).data('hide-all-contract');
                changeContractTypeHandler(contract, true);
            });

        searchResultsPage //
            .off('click', '[data-copy-transact-url]')
            .on('click', '[data-copy-transact-url]', function () {
                WhistleOut.copyToClipboard(wo$(this).data('copy-transact-url'));
            });

        wo$('body')
            .off('click', '[data-show-filter-modal]')
            .on('click', '[data-show-filter-modal]', function () {
                const tabName = wo$(this).data('show-filter-modal');
                showModalHandler(tabName);
            });

        if (wo$('body').find('#filters-original').length === 0) {
            wo$('[data-show-filter-modal]').hide();
            if (wo$('[data-show-filter-modal]').parent().prev().hasClass('divider')) {
                wo$('[data-show-filter-modal]').parent().prev().hide();
            }
        } else {
            wo$('[data-show-filter-modal]').show();
            if (wo$('[data-show-filter-modal]').parent().prev().hasClass('divider')) {
                wo$('[data-show-filter-modal]').parent().prev().show();
            }
        }
    }

    bindHideFeatureResults(hideFeaturedResultsHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage
            .off('click', '[data-results-action=HideAllFeaturedResults]')
            .on('click', '[data-results-action=HideAllFeaturedResults]', function () {
                hideFeaturedResultsHandler();
            });
    }

    bindDisableMaximumResultLimits(disableMaximumResultLimitsHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage
            .off('click', '[data-results-action=DisableMaximumResultLimits]')
            .on('click', '[data-results-action=DisableMaximumResultLimits]', function () {
                disableMaximumResultLimitsHandler();
            });
    }

    bindPreviewActions() {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage
            .off('click', 'a[data-results-action = "HideAllPreviewItems"]')
            .on('click', 'a[data-results-action = "HideAllPreviewItems"]', function () {
                searchResultsPage.find('.results-list div[data-result].bg-pink').hide();
            });

        searchResultsPage
            .off('click', 'a[data-results-action = "ShowPreviewItemsOnly"]')
            .on('click', 'a[data-results-action = "ShowPreviewItemsOnly"]', function () {
                searchResultsPage.find('.results-list div[data-result].bg-pink').show();
                searchResultsPage.find('.results-list div[data-result]:not(.bg-pink)').hide();
            });

        searchResultsPage
            .off('click', 'a[data-results-action = "RemovePreviewHighlighting"]')
            .on('click', 'a[data-results-action = "RemovePreviewHighlighting"]', function () {
                searchResultsPage.find('.results-list div[data-result].bg-pink').removeClass('bg-pink');
            });
    }

    bindSort(changeSortHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage //
            .off('click', '[data-sort-expression]')
            .on('click', '[data-sort-expression]', function () {
                const sortExpression = wo$(this).data('sort-expression');
                let sortAscending = true;
                const isAscending = wo$(this).data('sort-ascending');
                if (isAscending === false || isAscending === 'False') sortAscending = false;
                changeSortHandler(sortExpression, sortAscending);
            });
    }

    bindCoverageStrip(resetConnectionsHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage
            .off('click', '[data-nbn-strip] [data-nbn-reset]')
            .on('click', '[data-nbn-strip] [data-nbn-reset]', function (args) {
                args.preventDefault();
                resetConnectionsHandler();
            });
    }

    bindCoverageErrorStrip(retrySearchHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage
            .off('click', '[data-nbn-strip] [data-nbn-retry]')
            .on('click', '[data-nbn-strip] [data-nbn-retry]', function (args) {
                args.preventDefault();
                retrySearchHandler();
            });
    }

    bindModemCarouselLandingPage(changeModem) {
        const landingPage = wo$('.layout-landing');
        if (!landingPage.length) return;

        landingPage
            .find('[data-modem]')
            .off('click')
            .on('click', function (args) {
                args.preventDefault();
                const modemShortUrl = wo$(this).data('modem');
                changeModem(modemShortUrl);
                WhistleOut.scrollTo('#results-container');
            });
    }

    bindLandingPageCriteria(attribute, callback) {
        const landingPage = wo$('.layout-landing');
        if (!landingPage.length) {
            return;
        }

        landingPage
            .find('[data-' + attribute + ']')
            .off('click')
            .on('click', function (e) {
                e.preventDefault();
                const value = wo$(this).data(attribute);
                callback(value);
                WhistleOut.scrollTo('#results-container');
            });
    }

    bindCriteriaFilters(criteria, callback) {
        var trueOrNull = WhistleOut.trueOrNull;
        var falseOrNull = WhistleOut.falseOrNull;

        const setBundles = (criteria, key, val) => {
            if (!criteria.bundles) {
                criteria.bundles = {};
            }

            criteria.bundles[key] = val;
        };

        // See: ../utils/BroadbandQueryString.js
        const mappings = {
            channels: (e, val) => {
                setBundles(e, 'tvChannels', (val || '').split(','));
            },
            connection: (e, val) => {
                e.connectionTypes.values = (val || '').split(',');
            },
            data: (e, val) => {
                e.data = toNumber(val);
            },
            homephone: (e, val) => {
                setBundles(e, 'homePhone', val);
            },
            homephonecalls: (e, val) => {
                setBundles(e, 'homePhoneCalls', (val || '').split(','));
            },
            linerental: (e, val) => {
                setBundles(e, 'lineRental', val);
            },
            mobilephone: (e, val) => {
                setBundles(e, 'mobilePhone', val);
            },
            modem: (e, val) => {
                e.modemShortUrl = val;
            },
            peakdata: (e, val) => {
                e.peakDataOnly = trueOrNull(val);
            },
            showlessresults: (e, val) => {
                e.showFewerResults = falseOrNull(val);
            },
            simonly: (e, val) => {
                e.simOnly = trueOrNull(val);
            },
            speed: (e, val) => {
                e.speed = toNumber(val);
            },
            tv: (e, val) => {
                setBundles(e, 'tv', val);
            },
            type: (e, val) => {
                e.planType = val;
            }
        };

        WhistleOut.bindCriteriaFilters(
            wo$('body'),
            function (el, c) {
                callback(c);
                WhistleOut.scrollTo('#results-container');
                var modal = el.closest('.modal.in');
                if (modal) {
                    modal.removeClass('fade');
                    modal.modal('hide');
                }
            },
            criteria,
            mappings
        );
    }

    getCoveragePromise(criteria, coverageUrl) {
        const promise = new Promise(resolve =>
            window.setTimeout(() => {
                const queryString = generateQueryString(criteria);
                wo$.ajax({
                    url: `${coverageUrl}?${queryString}`,
                    type: 'GET',
                    success: result => {
                        if (result) {
                            console.log('coverageResult', result);
                            WhistleOut.setCoverageCookie(result.coverage);
                            if (result.allAvailableConnectionsSelected)
                                resolve({
                                    connectionTypes: [],
                                    hasFixed: result.hasFixed,
                                    useDefaultSuppliers: result.useDefaultSuppliers
                                });
                            else
                                resolve({
                                    connectionTypes: result.defaultConnectionTypes,
                                    hasFixed: result.hasFixed,
                                    useDefaultSuppliers: result.useDefaultSuppliers
                                });
                        } else {
                            resolve({
                                connectionTypes: [],
                                hasFixed: false
                                // useDefaultSuppliers: result.useDefaultSuppliers
                            });
                        }
                    }
                });
            })
        );
        return promise;
    }

    bindWidenResults(widenResultsHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;
        const options = {};

        // TODO: Try reducing cyclomatic complexity and enable the eslint rule
        // eslint-disable-next-line complexity
        searchResultsPage
            .find('[data-widen-result]')
            .off('click')
            // eslint-disable-next-line complexity
            .click(function (e) {
                e.preventDefault();
                const id = wo$(this).data('widen-result');
                const removeAll = id === 'remove-all';
                if (id === 'suppliers' || removeAll) {
                    options.includeAllSuppliers = true;
                }
                if (id === 'data' || removeAll) {
                    options.reduceData = true;
                }
                if (id === 'speed' || removeAll) {
                    options.reduceSpeed = true;
                }
                if (id === 'deals' || removeAll) {
                    options.noDealsOnly = true;
                }
                if (id === 'peakData' || removeAll) {
                    options.noPeakData = true;
                }
                if (id === 'uploadCounted' || removeAll) {
                    options.noUploadCounted = true;
                }
                if (id === 'staticIp' || removeAll) {
                    options.noStaticIp = true;
                }
                if (id === 'connectionTypes' || removeAll) {
                    options.includeMoreConnectionTypes = true;
                }
                if (id === 'bundles' || removeAll) {
                    options.noBundling = true;
                }
                if (id === 'modem' || removeAll) {
                    options.removeSelectedModem = true;
                }
                if (id === 'location' || removeAll) {
                    options.includeAllLocations = true;
                }
                if (id === 'contractLengths' || removeAll) {
                    options.includeAllContractLengths = true;
                }
                if (id === 'prepaidExpiry' || removeAll) {
                    options.includeAllPrepaidExpiry = true;
                }
                if (id === 'priceRange' || removeAll) {
                    options.increasePriceRange = true;
                }
                if (id === 'sort' || removeAll) {
                    options.sortBy = true;
                }

                widenResultsHandler(options);
                WhistleOut.scrollTo(0);

                if (removeAll) {
                    WhistleOut.trackEvent('BroadbandSearchResults', 'FilterChanged', 'Remove All');
                }
            });
    }

    bindLoadMore(loadMoreResultsHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;

        searchResultsPage //
            .off('click', '[data-load-tab]')
            .on('click', '[data-load-tab]', function () {
                const tab = wo$(this).data('load-tab');
                const current = wo$(this).data('current');
                wo$(this).find('span').show();
                loadMoreResultsHandler(tab, current);
            });
    }

    appendLoadMoreResults(tab, results) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;
        const pageSize = searchResultsPage.data('page-size');
        const $results = searchResultsPage.find('div#' + tab + ' div.results-list');
        const button = searchResultsPage.find('button[data-load-tab=' + tab + ']');
        const current = button.data('current');
        const total = button.data('total');
        const loadedRecords = current + pageSize;
        $results.append(results);
        WhistleOut.applyPopover($results);
        this.bindCta($results);
        button.data('current', loadedRecords);
        button.find('span').hide();
        if (total <= loadedRecords) {
            button.hide();
        }
        WhistleOut.notifications.fire('PagedResultsLoaded');
    }

    bindTab(changeTabHandler) {
        const searchResultsPage = wo$('#search-results-partial');
        if (!searchResultsPage.length) return;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const selectConnectionType = searchResultsPage.find('#selectConnectionType');
        WhistleOut.applySelectPicker(selectConnectionType);

        selectConnectionType.on('changed.bs.select', function() {
            const tab =$('option:selected', this).attr('data-tab-link');
            that.switchTabs(tab, searchResultsPage, changeTabHandler);
        });

        searchResultsPage.find('button[data-tab-button], button[data-tab-buttongroup], button[data-popover-button]').each(function () {
            const tab = wo$(this);
            const content = tab.data('tooltip-text');
            tab.popover({
                container: 'body',
                trigger: 'hover',
                placement: 'top',
                content: content,
                html: true
            });
        });

        searchResultsPage //
            .off('click', '[data-goto-tab]')
            .on('click', '[data-goto-tab]', function () {
                const tab = wo$(this).data('goto-tab');
                that.switchTabs(tab, searchResultsPage, changeTabHandler);
            });

        searchResultsPage //
            .off('click', '[data-tab-link]')
            .on('click', '[data-tab-link]', function () {
                const tab = wo$(this).data('tab-link');
                that.switchTabs(tab, searchResultsPage, changeTabHandler);
            });

        searchResultsPage //
            .off('click', '[data-tab-linkbutton]')
            .on('click', '[data-tab-linkbutton]', function () {
                const tab = wo$(this).data('tab-linkbutton');
                that.switchTabs(tab, searchResultsPage, changeTabHandler);
            });

        searchResultsPage //
            .off('click', '[data-tab-button]')
            .on('click', '[data-tab-button]', function () {
                const tab = wo$(this).data('tab-button');
                that.switchTabs(tab, searchResultsPage, changeTabHandler);
            });
    }

    switchTabs(tab, searchResultsPage, changeTabHandler) {
        this.changeCurrentActiveTab(tab, searchResultsPage);
        changeTabHandler(tab);
    }

    changeCurrentActiveTab(tab, searchResultsPage) {
        if (!searchResultsPage) {
            searchResultsPage = wo$('#search-results-partial');
            if (!searchResultsPage.length) return;
        }
        const selectedTabLink = searchResultsPage.find('a[data-tab-link=' + tab + ']');
        searchResultsPage.find('a[data-tab-link] > i').addClass('hidden');
        selectedTabLink.find('i').removeClass('hidden');
        selectedTabLink.closest('div').find('button span.dropdownText').text(selectedTabLink.data('text'));
        selectedTabLink.closest('div').find('button span.resultCount').text(selectedTabLink.data('count'));
        selectedTabLink.closest('div').find('button span.subtext').text(selectedTabLink.data('subtext'));

        const selectedTabLinkButton = searchResultsPage.find('a[data-tab-linkbutton=' + tab + ']');
        searchResultsPage.find('a[data-tab-linkbutton] > i').addClass('hidden');
        searchResultsPage.find('a[data-tab-linkbutton]').closest('div.btn-group').find('button').removeClass('active');
        if (selectedTabLinkButton.length > 0) {
            selectedTabLinkButton.find('i').removeClass('hidden');
            const button = selectedTabLinkButton.closest('div.btn-group').find('button');
            const image = selectedTabLinkButton.data('image');
            if (image.length > 0) {
                button.find('span[data-tab-image]').removeClass('hidden');
                button.addClass('display-flex justify-content-flex-start');
                button.find('span[data-tab-image]>img').src = image;
            } else {
                button.find('span[data-tab-image]').addClass('hidden');
                button.removeClass('display-flex justify-content-flex-start');
                button.find('span[data-tab-image]>img').src = null;
            }
            button.find('span.dropdownText').text(selectedTabLinkButton.data('text'));
            button.find('span.resultCount').text(selectedTabLinkButton.data('count'));
            button.find('span.subtext').text(selectedTabLinkButton.data('subtext'));
            button.addClass('active');
            const content = selectedTabLinkButton.data('tooltip-text');
            const popover = button.data('bs.popover');
            popover.options.content = content;
            popover.tip().find('.popover-content').html(content);
        }
        searchResultsPage.find('button[data-tab-button]').removeClass('active');
        searchResultsPage.find('button[data-tab-button=' + tab + ']').addClass('active');
        searchResultsPage
            .find('div[data-results-container] div#' + tab)
            .addClass('active')
            .siblings()
            .removeClass('active');
    }

    bindPlanSummary(callback) {
        wo$('table[data-plan-summary] tr[data-supplier], table[data-plan-summary] a')
            .off('click')
            .on('click', function () {
                const supplierShortUrl = wo$(this).data('supplier');
                const maxResults = wo$(this).data('max-results');
                callback(supplierShortUrl, maxResults);
                WhistleOut.scrollTo('#landing-results');
            });
    }

    bindCta(results) {
        if (!results) results = wo$('#search-results-partial');
        WhistleOut.bindCta(results);
    }

    showDownloadSpeed(speedTestResult) {
        if (speedTestResult && speedTestResult.downloadSpeed && speedTestResult.isCompleted) {
            wo$('[data-show-when-no-speed-test]').hide();
            wo$('[data-show-when-speed-test]').show();
        } else {
            wo$('[data-show-when-no-speed-test]').show();
            wo$('[data-show-when-speed-test]').hide();
        }

        if (speedTestResult && speedTestResult.downloadSpeed) {
            wo$('[data-speed-test-result]').text(speedTestResult.downloadSpeed);
            wo$('[data-show-when-speed-test-result]').show();
        } else {
            wo$('[data-show-when-speed-test-result]').hide();
        }

        if (speedTestResult && speedTestResult.lastResultDate) {
            var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            wo$('[data-speed-test-date]').text(
                new Date(speedTestResult.lastResultDate).toLocaleDateString('en-AU', dateOptions)
            );
            wo$('[data-show-when-test-date]').show();
        } else {
            wo$('[data-show-when-test-date]').hide();
        }
    }

    speedTestInProgress(isInProgress) {
        if (isInProgress) {
            wo$('[data-run-speed-test]').prop('disabled', true);
            wo$('[data-run-speed-test]').find('.fa-spinner').show();
            wo$('[data-speed-test-result]').addClass('c-gray-light');
        } else {
            wo$('[data-run-speed-test]').prop('disabled', false);
            wo$('[data-run-speed-test]').find('.fa-spinner').hide();
            wo$('[data-speed-test-result]').removeClass('c-gray-light');
        }
    }

    bindSpeedTestResult(ignorePopup) {
        var speedTestResult = Cookies.get('woSpeedTestResult');
        if (speedTestResult && speedTestResult.length) {
            speedTestResult = JSON.parse(speedTestResult);
        }

        this.showDownloadSpeed(speedTestResult);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var that = this;

        wo$('[data-run-speed-test]')
            .off()
            .click(function () {
                wo$('[data-show-when-error]').hide();
                that.speedTestInProgress(true);

                WhistleOut.SpeedTest.getSpeedTestParameters(
                    function (resp) {
                        try {
                            if (resp.protocol === 'ndt7') {
                                const results = resp.results[0];
                                wo$('[data-show-when-no-speed-test]').show();
                                wo$('[data-show-when-speed-test]').hide();
                                wo$('[data-show-when-speed-test-result]').show();
                                wo$('[data-speed-test-result]').show();

                                WhistleOut.SpeedTest.runNdt7Test(
                                    results.urls['wss:///ndt/v7/download'],
                                    'download',
                                    wo$('[data-speed-test-result]'),
                                    () => {
                                        that.speedTestInProgress(false);
                                        wo$('[data-show-when-no-speed-test]').hide();
                                        wo$('[data-show-when-speed-test]').show();
                                    }
                                );
                            } else {
                                WhistleOut.SpeedTest.testSpeed(resp.fqdn, true, false, function (result) {
                                    if (result.downloadSpeed) {
                                        that.showDownloadSpeed(result);
                                    }
                                    if (result.hasErrors && !result.downloadSpeed) {
                                        that.speedTestInProgress(false);
                                        wo$('[data-show-when-error]').show();
                                    } else if (result.isCompleted || result.hasErrors) {
                                        that.speedTestInProgress(false);
                                    } else {
                                        that.speedTestInProgress(true);
                                    }
                                });
                            }
                        } catch (e) {
                            that.speedTestInProgress(false);
                            wo$('[data-show-when-error]').show();
                        }
                    },
                    function () {
                        that.speedTestInProgress(false);
                        wo$('[data-show-when-error]').show();
                    }
                );
            });

        if (!ignorePopup) {
            wo$('[data-toggle="popover"]')
                .off('shown.bs.popover')
                .on('shown.bs.popover', function () {
                    that.bindSpeedTestResult(true);
                });
        }
    }

    bindNbnAlertModal() {
        const modal = wo$('#modal-nbn-alert');
        if (!modal.length) return;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;

        modal.off('shown.bs.modal').on('shown.bs.modal', function () {
            modal.find('#nbnName').focus();
        });

        modal
            .find('#nbnSubmit')
            .off('click')
            .on('click', function () {
                that.clearNbnAlertModalErrort(modal);
                if (!that.validateNbnAlertModal(modal)) return;

                const $this = wo$(this);
                $this.find('.fa-spinner').show();
                $this.prop('disabled', true);
                wo$.ajax({
                    url: '/Ajax/Broadband/NbnAlert',
                    data: {
                        name: modal.find('#nbnName').val(),
                        email: modal.find('#nbnEmail').val(),
                        generalOptIn: modal.find('#nbnOptInYes').is(':checked')
                    },
                    type: 'POST',
                    success: function () {
                        modal.find('#nbnForm, #nbnFooter').hide();
                        modal.find('#nbnSuccess').show();
                    },
                    error: function () {
                        modal.find('#nbnError').show();
                    },
                    complete: function () {
                        $this.find('.fa-spinner').hide();
                        $this.prop('disabled', false);
                    }
                });
            });
    }

    validateNbnAlertModal(modal) {
        let valid = true;
        const name = modal.find('#nbnName');
        if (name.val().trim() === '') {
            valid = false;
            this.showNbnAlertModalError(name, '[data-validation-error]');
        }
        const email = modal.find('#nbnEmail');
        const config = WhistleOut.getSiteConfiguration();
        const regex = new RegExp(config.regex.email);
        if (email.val().trim() === '') {
            valid = false;
            this.showNbnAlertModalError(email, '[data-validation-error]');
        } else if (!regex.test(email.val().trim())) {
            valid = false;
            this.showNbnAlertModalError(email, '[data-validation-error-invalid]');
        }
        const optInYes = modal.find('#nbnOptInYes');
        const optInNo = modal.find('#nbnOptInNo');
        if (!optInYes.is(':checked') && !optInNo.is(':checked')) {
            valid = false;
            this.showNbnAlertModalError(wo$('#nbnOptInRow'), '[data-validation-error]');
        }

        return valid;
    }

    showNbnAlertModalError(field, messageSelector) {
        field.parent().addClass('has-error');
        field.siblings(messageSelector).show();
    }

    clearNbnAlertModalErrort(modal) {
        modal.find('.form-group').removeClass('has-error');
        modal.find('[data-validation-error]').hide();
        modal.find('[data-validation-error-invalid]').hide();
        modal.find('#nbnError').hide();
    }
}

// TODO: Extract a component for the queue
BroadbandResultsAdaptor.coverageRequestsQueue = [];
