import omit from 'lodash/omit';
import React, { useCallback, useMemo, useState } from 'react';

import type { Address } from '../../../../../../Shared/js/@types/Address';
import { nameof } from '../../../../../../Shared/js/utils/typescript';
import AddressSearch, {
    AddressSearchProps
} from '../../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import Spinner from '../../../../../../Shared/React/js/components/Spinner';
import IncompleteAddressWarning from './IncompleteAddressWarning';

const Header = (props: { isModal: boolean; title: string; subtitle: string }) => {
    return props.isModal ? (
        <>
            <h4 className="font-feature font-8 font-800 line-height-105 c-gray-darker mar-b-3">{props.title}</h4>
            <p className="font-5 mar-0 mar-b-5">{props.subtitle}</p>
        </>
    ) : (
        <>
            <h4 className="font-feature font-9 font-800 line-height-12 c-gray-darker mar-b-4">{props.title}</h4>
            <p className="font-6 mar-0 mar-b-6">{props.subtitle}</p>
        </>
    );
};

const SearchButton = (
    props: {
        isLoading?: boolean;
    } & React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
    const htmlProps = useMemo(() => {
        const omitNames = [nameof<typeof props>('isLoading')];
        return omit(props, omitNames);
    }, [props]);

    return (
        <button {...htmlProps} type="button" className="btn btn-primary btn-lg btn-block mar-b-5">
            {props.isLoading && <Spinner />}
            Continue
        </button>
    );
};

const AddressSearchPanel = (props: {
    addressSearchProps: AddressSearchProps;
    isAddressWarningEnabled?: boolean;
    isLoading?: boolean;
    isModal?: boolean;
    onContinue: (address: Address) => void;
}) => {
    const [address, setAddress] = useState(props.addressSearchProps?.current);

    const handleContinueClick = useCallback(() => {
        if (props.onContinue) {
            props.onContinue(address);
        }
    }, [address, props]);

    const isPrivateDataEnabled = useMemo(() => WhistleOut.getPrivateDataCookieValue(), []);
    const isIncompleteAddress = useMemo(() => address && !address?.streetNumber, [address]);
    const isSearchDisabled = useMemo(() => !address || props.isLoading, [address, props.isLoading]);

    const privateDataButton = useMemo(
        () => <SearchButton data-toggle="modal" data-target={`#${WhistleOut.privateDataModalId}`} />,
        []
    );

    return (
        <>
            <div className="row">
                <div className="col-xs-24">
                    <Header
                        isModal={props.isModal}
                        title="Check plan availability"
                        subtitle="Enter a complete street address to check plan availability in your area."
                    />
                </div>
                <div className="col-xs-24 col-sm-18">
                    <AddressSearch
                        {...props.addressSearchProps}
                        current={address}
                        className="form-group text-left"
                        inputClassName="input-lg"
                        hideLabel={true}
                        skipGlobalUpdate={true}
                        tooltipLink={null}
                        tooltipDescription={null}
                        onChange={setAddress}
                    />
                </div>
                <div className="col-xs-24 col-sm-6">
                    {isPrivateDataEnabled ? (
                        privateDataButton
                    ) : (
                        <SearchButton
                            disabled={isSearchDisabled}
                            isLoading={props.isLoading}
                            onClick={handleContinueClick}
                        />
                    )}
                </div>
            </div>
            {isIncompleteAddress && <IncompleteAddressWarning />}
        </>
    );
};

export default AddressSearchPanel;
