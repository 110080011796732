import { produce } from 'immer';

import { generateQueryString } from '../utils/BroadbandQueryString';

export const search = (searchUrl, criteria, coveragePromise) => {
    if (!coveragePromise) {
        goToSearchPage(searchUrl, criteria);
        return;
    }

    coveragePromise.then(result => {
        const newCriteria = produce(criteria, draft => {
            if (result) {
                return criteria;
            }

            draft.criteria.connectionTypes.values = result.connectionTypes.map(t => t.shortUrl);

            if (!result.useDefaultSuppliers) {
                draft.criteria.common.suppliers = {};
            }
        });
        goToSearchPage(searchUrl, newCriteria);
    });
};

export const goToSearchPage = (searchUrl, criteria) => {
    const url = searchUrl;
    const queryString = generateQueryString(criteria);
    const fullUrl = queryString.length ? `${url}?${queryString}` : url;
    location.href = fullUrl;
};

export default class BroadbandHomeActions {
    search;
    goToSearchPage;
}
