import React, { useCallback } from 'react';

import type { KeyValue } from '../../../../../../Shared/js/@types/KeyValue';
import SliderWithLabel from '../../../../../../Shared/React/js/components/sliders/SliderWithLabel';

interface Props {
    current: number;
    anyLabel: string;
    fromLabel: string;
    values: KeyValue[];
    onValueChanged: (value: number) => void;
}

const ModalSpeedSlider = (props: Props) => {
    const getLabel = useCallback(
        (labelText: string | JSX.Element) => <strong className="font-5">{labelText}</strong>,
        []
    );

    return (
        <SliderWithLabel
            current={props.current}
            values={props.values}
            anyLabel={props.anyLabel}
            fromLabel={props.fromLabel}
            containerClassName="pad-t-4"
            labelTemplate={getLabel}
            sliderClassName="rc-slider-container pad-x-3 pad-x-5-xs mar-y-2"
            onValueChanged={props.onValueChanged}
        />
    );
};

export default ModalSpeedSlider;
