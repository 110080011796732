import React, { useCallback, useEffect, useRef } from 'react';

const Modal = (props: {
    isOpen?: boolean;
    contentClassNames?: string;
    children: React.ReactNode;
    onClose?(): void;
}) => {
    const containerRef = useRef();

    const onModalAction = useCallback((ref: HTMLElement, isOpen: boolean) => {
        const action = isOpen ? 'show' : 'hide';
        wo$(ref).modal(action);
    }, []);

    const onClose = useCallback(() => {
        onModalAction(containerRef.current, false);
        if (props.onClose) {
            props.onClose();
        }
    }, [onModalAction, props]);

    useEffect(() => {
        const ref = containerRef.current;
        onModalAction(ref, props.isOpen);
        return () => {
            onModalAction(ref, false);
        };
    }, [onModalAction, props.isOpen]);

    useEffect(() => {
        const container = wo$(containerRef.current);
        container.on('hidden.bs.modal', onClose);

        return () => {
            container.off('hidden.bs.modal', onClose);
        };
    }, [onClose]);

    return (
        <div ref={containerRef} className="modal fade in" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body pad-x-6 pad-y-6">
                        <div className="row">
                            <div className="col-xs-24">
                                <button
                                    data-toggle="modal"
                                    data-dismiss="modal"
                                    className="btn btn-info float-right"
                                    type="button"
                                    onClick={onClose}
                                >
                                    <span className="fa fa-times font-5"></span>
                                </button>
                            </div>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
