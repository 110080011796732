import React, { useCallback, useEffect, useRef } from 'react';

import type { KeyValue } from '../../../../../../Shared/js/@types/KeyValue';
import ModalSpeedSlider from './ModalSpeedSlider';

interface Props {
    currentSpeed: number;
    speedValues: KeyValue[];
    anyLabel: string;
    fromLabel: string;
    speedDescription: string;
    speedContentFragment: string;
    onSpeedChange: (value: number) => void;
}

const SpeedTab = (props: Props) => {
    const wrapper = useRef();

    const handleOnMount = useCallback(() => {
        const dom = wo$(wrapper.current);
        dom.find('[data-min-speed]')
            .off('click')
            .on('click', e => {
                const value = wo$(e.currentTarget).closest('[data-min-speed]').data('min-speed');
                props.onSpeedChange(value);
            });
    }, [props]);

    useEffect(() => handleOnMount(), [handleOnMount]);

    const createMarkup = (html: string) => {
        return { __html: html };
    };

    return (
        <div ref={wrapper}>
            <div className="row pad-t-2 mar-b-3">
                <div className="col-md-24">
                    <h5 className="font-6 mar-0">{props.speedDescription}</h5>
                </div>
            </div>
            <div className="row sep-b-1 mar-y-5 pad-b-6">
                <div className="col-md-20 col-md-offset-2">
                    <ModalSpeedSlider
                        current={props.currentSpeed}
                        values={props.speedValues}
                        anyLabel={props.anyLabel}
                        fromLabel={props.fromLabel}
                        onValueChanged={props.onSpeedChange}
                    />
                </div>
            </div>
            <div className="row mar-b-5" dangerouslySetInnerHTML={createMarkup(props.speedContentFragment)} />
        </div>
    );
};

export default SpeedTab;
