import React, { useCallback, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSearchParams } from 'react-router-dom';

import type { Address } from '../../../../../Shared/js/@types/Address';
import { RootState } from '../../../../../Shared/js/redux/rootReducer';
import SearchAddressActions from '../../../../../Shared/React/js/actions/SearchAddressActions';
import AppComponent from '../../../../../Shared/React/js/components/AppComponent';
import type { SmartMoveVerifyPageModel } from '../../../../js/@types/SmartMoveVerifyPageModel';
import { AvailabilityCheckPanel, OnTransactUrlChangeArgs } from './availabilityCheck/AvailabilityCheckPanel';

const connector = connect((state: RootState, ownProps: SmartMoveVerifyPageModel) => {
    return ownProps;
}, {});

export type Props = ConnectedProps<typeof connector>;

const goTo = (url: string) => {
    window.location.href = url;
};

const SmartMoveVerifyPageComponent = (props: Props) => {
    const [address, setAddress] = useState(props.addressSearch?.address);

    const handleShowPlans = useCallback(
        (address: Address) => {
            setAddress(address);
            SearchAddressActions.setAddressCookie(address);
            const url = `${props.searchUrl}?${createSearchParams({ address: address?.label || '' })}`;
            goTo(url);
        },
        [props.searchUrl]
    );

    const handleTransactUrlChange = useCallback((args: OnTransactUrlChangeArgs) => {
        goTo(args.url);
    }, []);

    return (
        <>
            <AvailabilityCheckPanel
                autoSubmitOnLoad
                address={address}
                addressSearchPlaceHolder={props.addressSearch.text.addressSearchPlaceHolder}
                apiKey={props.addressSearch.apiKey}
                countryCode={props.addressSearch.countryCode}
                onShowPlans={handleShowPlans}
                onTransactUrlChange={handleTransactUrlChange}
                queryDelay={props.addressSearch.queryDelay}
                tooltipDescription={props.addressSearch.text.tooltipDescription}
                tooltipLink={props.addressSearch.text.tooltipLink}
                productShortUrl={null}
                supplierShortUrl={props.supplierShortUrl}
            />
        </>
    );
};

const SmartMoveVerifyPageConnected = connector(SmartMoveVerifyPageComponent);

export default function SmartMoveVerifyPage(props: Props) {
    return (
        <React.StrictMode>
            <AppComponent>
                <SmartMoveVerifyPageConnected {...props} />
            </AppComponent>
        </React.StrictMode>
    );
}
