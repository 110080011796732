import React, { useEffect } from 'react';

import { ScreenSize, useScreenSize } from '../utils/Hooks';

interface Props {
    children: {
        desktop: string | JSX.Element;
        mobile: string | JSX.Element;
        tablet: string | JSX.Element;
    };
    onScreenSizeChange?: (screenSize: ScreenSize) => void;
}

const ResponsiveLayout = (props: Props) => {
    const screenSize = useScreenSize();

    useEffect(() => {
        if (props.onScreenSizeChange) {
            props.onScreenSizeChange(screenSize.type);
        }
    }, [props, screenSize.type]);

    return (
        <>
            {screenSize.isMobile
                ? props.children.mobile
                : screenSize.isTablet
                    ? props.children.tablet
                    : props.children.desktop}
        </>
    );
};

export default ResponsiveLayout;
