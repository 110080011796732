import { SliderProps } from 'rc-slider';
import React, { forwardRef } from 'react';

const SliderHandle = forwardRef((props: Parameters<SliderProps['handle']>[0], ref: React.Ref<HTMLDivElement>) => {
    return (
        <div
            ref={ref}
            className="rc-slider-handle rc-slider-handle-2 rc-slider-handle-upper"
            style={{ left: `${props.offset}%` }}
            aria-disabled={props.disabled}
        ></div>
    );
});

SliderHandle.displayName = 'SliderHandle';
export default SliderHandle;
