import React, { useCallback, useRef } from 'react';

interface Props {
    title: string;
    description: string;
    retryLabel: string;
    onRetry: () => void;
}

const ErrorPanel = (props: Props) => {
    const containerRef = useRef(null);
    const handleRetry = useCallback(() => {
        const container = wo$(containerRef.current);
        container.modal('hide');
        wo$('body').removeClass('modal-open');
        wo$('.modal-backdrop').remove();

        if (props.onRetry) {
            props.onRetry();
        }
    }, [props]);

    return (
        <div
            className="modal fade"
            id="modal-error"
            ref={containerRef}
            data-backdrop="static"
            data-keyboard="false"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body [ pad-x-6  pad-y-12 ]">
                        <div className="row">
                            <div className="col-xs-24 col-sm-15 col-sm-offset-6 col-lg-14 col-log-offset-2">
                                <div className="text-center">
                                    <h2 className="font-7 mar-t-0 mar-t-5-xs">
                                        <strong>{props.title}</strong>{' '}
                                        <span className="text-nowrap">{props.description}</span>
                                    </h2>
                                    <div className="pad-t-3 font-400" id="enterAddressLocationForm">
                                        <div className="row">
                                            <div className="text-center-xs">
                                                <button onClick={handleRetry} type="button" className="btn btn-primary">
                                                    {props.retryLabel}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPanel;
