import React, { useCallback } from 'react';

import OptionGroup from './OptionGroup';
import OptionImageButton from './OptionImageButton';

const SuppliersOptionGroup = ({
    items,
    selectedKeys,
    getKey,
    availableSupplierIds,
    showHeader,
    businessOnly,
    text,
    onSelectedItemsChanged,
    onShowAllProviders,

}) => {

    const getTopEligibleItems = useCallback((items) => {
        return items.filter(item =>
            // The provider should be available in the search result set,
            // otherwise selecting that provider would cause an empty result for the search
            !availableSupplierIds || availableSupplierIds.indexOf(item.id) > -1);

    }, [availableSupplierIds]);

    const onSelectedItemsChangedHandler = useCallback(shortUrls => {
        WhistleOut.trackEvent(
            'BroadbandSearchResults',
            'FilterChanged',
            'Providers: ' + (
                shortUrls.length === 0
                    ? 'Any'
                    : shortUrls.join(',')
            ));

        if (onSelectedItemsChanged) {
            onSelectedItemsChanged(shortUrls);
        }

    }, [onSelectedItemsChanged]);

    const renderHasNoItems = (header) => {
        return (
            <React.Fragment>
                {
                    header
                        ? <div className="mar-b-2">
                            {header}
                        </div>
                        : null
                }
                <div className="c-gray-light pad-x-9">
                    {text.noProvidersFoundForCriteria}
                </div>
            </React.Fragment>
        );
    };

    const renderItemButton = useCallback((item, className, isSelected, onSelected) => {
        return (
            <OptionImageButton
                key={item.id}
                imageSrc={item.smallImageUrl}
                imageAlt={item.name}
                className={className}
                selected={isSelected}
                onSelected={onSelected} />
        );

    }, []);

    const header = showHeader ? text.heading : null;

    const availableItems = items.filter(
        item =>
            !!item.isAvailable &&
            (!businessOnly || item.isBusiness)
    );

    if (!availableItems || availableItems.length === 0) {
        return renderHasNoItems(header);
    }

    const topEligibleItems = getTopEligibleItems(availableItems);
    if (topEligibleItems.length === 0) {
        return renderHasNoItems(header);
    }

    return (
        <OptionGroup
            items={availableItems}
            selectedKeys={selectedKeys}
            getKey={getKey}
            getTopEligibleItems={getTopEligibleItems}
            renderItemButton={renderItemButton}
            onSelectedItemsChanged={onSelectedItemsChangedHandler}
            onMoreClick={onShowAllProviders}
            header={header}
            text={text}
            showSelectedCount
            maxItemsCount={6}
        />
    );
};

export default SuppliersOptionGroup;
