import React, { useCallback, useEffect, useState } from 'react';

import RangeSlider from '../../../../../../Shared/React/js/components/sliders/RangeSlider';

const ModalSpendSlider = ({
    //
    minValue: minValueProps,
    maxValue: maxValueProps,
    values,
    resources,
    updateSpend
}) => {
    const [minValue, setMinValue] = useState(minValueProps);
    const [maxValue, setMaxValue] = useState(maxValueProps);

    useEffect(() => {
        setMinValue(minValueProps);
    }, [minValueProps]);

    useEffect(() => {
        setMaxValue(maxValueProps);
    }, [maxValueProps]);

    const getLabel = useCallback(
        (min, max) => {
            const lowestValue = values[0].key;
            const biggestValue = values[values.length - 1].key;

            min = min || lowestValue;
            max = max || biggestValue;
            if (min === lowestValue && max === biggestValue) {
                return resources.anyPrice;
            }

            const minValueText = values.find(v => v.key === min).value;
            const maxValueText = values.find(v => v.key === max).value;
            return minValueText + ' - ' + maxValueText;
        },
        [resources.anyPrice, values]
    );

    const handleOnValueUpdating = useCallback((min, max) => {
        setMinValue(min);
        setMaxValue(max);
    }, []);

    return (
        <React.Fragment>
            <div className="font-5">
                {resources.monthly}: <strong>{getLabel(minValue, maxValue)}</strong>
            </div>
            <div className="rc-slider-container pad-x-3 pad-x-5-xs mar-y-2">
                <RangeSlider
                    data={values.map(a => a.key)}
                    minValue={minValue}
                    maxValue={maxValue}
                    onValueChanged={updateSpend}
                    onValueUpdating={handleOnValueUpdating}
                />
            </div>
        </React.Fragment>
    );
};

export default ModalSpendSlider;
