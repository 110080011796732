import React, { useCallback } from 'react';

import AddressSearch from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import { ScreenSize } from '../../../../../Shared/React/js/utils/Hooks';
import type { Props } from './@types/Filters';
import SearchButton from './SearchButton';
import SpeedDataSelect from './SpeedDataSelect';

const screenSize: ScreenSize = ScreenSize.Tablet;

const FiltersSm = (props: Props) => {
    const onSearchClick = useCallback(() => props.onSearchClick(screenSize), [props]);

    return (
        <>
            <div className="pad-t-5 pad-b-3-xs pad-b-5-sm">
                <div className="row">
                    <div className="col-sm-14 col-sm-offset-1">
                        <div className="visible-sm">
                            <AddressSearch
                                {...props.addressSearchProps}
                                ref={props.addressSearchProps.ref}
                                className={`form-group ${props.marginCss}`}
                                inputClassName="font-5 input-lg"
                            />
                            <div className="input-group input-group-lg">
                                <SpeedDataSelect {...props.speedDataSelectProps} screenSize={screenSize} />
                                <div className="input-group-btn">
                                    <SearchButton
                                        text={props.searchButtonText}
                                        isLoading={props.isLoading}
                                        onClick={onSearchClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FiltersSm;
