import React, { useCallback, useEffect, useState } from 'react';

import Slider from '../../../../../../Shared/React/js/components/sliders/Slider';

const ModalUpfrontSlider = ({ updateUpfront, ...props }) => {
    const [rawValue, setRawValue] = useState(null);

    useEffect(() => {
        setRawValue(props.value);
    }, [props.value]);

    const handleSliderValueUpdating = useCallback(value => {
        setRawValue(value);
    }, []);

    const getLabel = value => {
        return props.values.find(v => v.key === value).value;
    };

    const value = rawValue !== null ? rawValue : props.value;

    return (
        <React.Fragment>
            <div className="font-5">
                {props.resources.maxUpfront}: <strong>{getLabel(value)}</strong>
            </div>
            <div className="rc-slider-container pad-x-3 pad-x-5-xs mar-y-2">
                <Slider
                    data={props.values.map(a => a.key)}
                    current={value}
                    onValueChanged={updateUpfront}
                    onValueUpdating={handleSliderValueUpdating}
                />
            </div>
        </React.Fragment>
    );
};

export default ModalUpfrontSlider;
