/* eslint react/prop-types: error */

import { produce } from 'immer';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import OptionTextButton from './OptionTextButton';

const OptionGroup = ({
    items = [],
    selectedKeys: selectedKeysProps = [],
    getKey,
    maxItemsCount = 3,
    showSelectedCount,
    anyButtonPositionLast,
    showMoreVisible,
    header,
    text,
    onMoreClick: onMoreClickHandler,
    onSelectedItemsChanged: onSelectedItemsChangedHandler,
    renderItemButton: renderItemButtonHandler,
    renderAnyButton: renderAnyButtonHandler,

    // TOD: USe 'items' instead and remove this
    getTopEligibleItems: getTopEligibleItemsHandler
}) => {
    const [selectedKeys, setSelectedKeys] = useState(selectedKeysProps || []);

    useEffect(() => {
        setSelectedKeys(selectedKeysProps || []);
    }, [selectedKeysProps]);

    const getItemByKey = useCallback(
        key => {
            return items.find(p => getKey(p) === key);
        },
        [getKey, items]
    );

    const onSelectedItemsChanged = useCallback(
        keys => {
            const ordered = (keys || []).slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

            setSelectedKeys(ordered);
            onSelectedItemsChangedHandler(ordered);
        },
        [onSelectedItemsChangedHandler]
    );

    const onAnyClick = useCallback(() => {
        // Ignore if everything is already selected
        if (!selectedKeys || selectedKeys.length === 0) {
            return;
        }
        onSelectedItemsChanged([]);
    }, [onSelectedItemsChanged, selectedKeys]);

    const onMoreClick = useCallback(
        event => {
            event.preventDefault();
            if (onMoreClickHandler) {
                onMoreClickHandler(event);
            }
        },
        [onMoreClickHandler]
    );

    const onItemSelected = (item, selected) => {
        const keys = produce(selectedKeys, draft => {
            const key = getKey(item);
            if (selected) {
                draft.push(key);
            } else {
                draft.splice(draft.indexOf(key), 1);
            }
        });

        onSelectedItemsChanged(keys);
    };

    const getSelectedItems = useCallback(() => {
        return selectedKeys.map(key => getItemByKey(key)).filter(p => !!p);
    }, [getItemByKey, selectedKeys]);

    // TOD: USe 'items' instead and remove this
    const getTopEligibleItems = useCallback(() => {
        if (getTopEligibleItemsHandler) {
            return getTopEligibleItemsHandler(items);
        }

        return items;
    }, [getTopEligibleItemsHandler, items]);

    const topEligibleItems = getTopEligibleItems();
    const topItemsCount = Math.min(maxItemsCount, topEligibleItems.length);

    const renderItemButton = (item, onSelected) => {
        if (renderItemButtonHandler) {
            const isSelected = selectedKeys.some(key => getKey(item) === key);

            const lastVisibleItem = topEligibleItems.slice(0, topItemsCount)[topItemsCount - 1];
            const className = item === lastVisibleItem ? 'hidden-md' : null;

            return renderItemButtonHandler(item, className, isSelected, onSelected);
        }
    };

    const itemsCount = items.length;
    const selectedItems = getSelectedItems();
    const unselectedCount = items.length - (selectedKeys || []).length;
    const selectedCount = selectedItems.length;

    const isAllSelected =
        selectedCount === 0 ||
        unselectedCount === 0 ||
        selectedCount === items.length ||
        unselectedCount === items.length;

    const anyButton = renderAnyButtonHandler ? (
        renderAnyButtonHandler(isAllSelected, onAnyClick)
    ) : (
        <OptionTextButton text={text.all} selected={isAllSelected} onSelected={onAnyClick} />
    );

    return (
        <React.Fragment>
            {header ? (
                <div className="mar-b-5 font-4 c-gray-light">
                    {header}
                    {showSelectedCount && !isAllSelected && selectedItems.length > 0 ? (
                        <span>
                            <span className="c-gray-lighter"> | </span>
                            <a href="#" onClick={onMoreClick}>{`${selectedItems.length}\u00a0${text.selected}`}</a>
                        </span>
                    ) : null}
                </div>
            ) : null}
            {!anyButtonPositionLast ? anyButton : null}
            {
                // Top items
                topEligibleItems
                    .slice(0, topItemsCount)
                    .map(item => renderItemButton(item, selected => onItemSelected(item, selected)))
            }
            {anyButtonPositionLast ? anyButton : null}
            {showMoreVisible || itemsCount > topItemsCount ? (
                <button
                    type="button"
                    className="mar-l-4 btn btn-info btn-filter pad-0 mar-0 c-blue"
                    onClick={onMoreClick}
                >
                    <span className="font-4 display-block line-height-1">
                        <strong>{itemsCount - topItemsCount}</strong>
                    </span>
                    <span className="font-3 display-block line-height-1">{text.more.toLowerCase()}</span>
                </button>
            ) : null}
        </React.Fragment>
    );
};

/* eslint-disable import/no-named-as-default-member */
OptionGroup.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedKeys: PropTypes.arrayOf(PropTypes.string),
    getKey: PropTypes.func.isRequired,
    maxItemsCount: PropTypes.number,
    showSelectedCount: PropTypes.bool,
    anyButtonPositionLast: PropTypes.bool,
    showMoreVisible: PropTypes.bool,
    header: PropTypes.oneOfType([
        //
        PropTypes.string,
        PropTypes.number,
        PropTypes.element
    ]),
    text: PropTypes.shape({
        all: PropTypes.string.isRequired,
        selected: PropTypes.string.isRequired,
        showAll: PropTypes.string.isRequired,
        more: PropTypes.string.isRequired
    }).isRequired,
    onMoreClick: PropTypes.func,
    onSelectedItemsChanged: PropTypes.func,
    renderItemButton: PropTypes.func.isRequired,
    renderAnyButton: PropTypes.func,
    getTopEligibleItems: PropTypes.func.isRequired
};
/* eslint-enable */

export default OptionGroup;
