import React, { useContext, useMemo } from 'react';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import { BrowserRouter, useInRouterContext } from 'react-router-dom';

import { store } from '../../../js/redux/store';

const ReduxEnabled = props => {
    const context = useContext(ReactReduxContext);

    const wrapper = useMemo(() => <ReduxProvider store={store}>{props.children}</ReduxProvider>, [props.children]);

    const isInContext = useMemo(() => !!context, [context]);
    return isInContext ? <>{props.children}</> : wrapper;
};

const RouterEnabled = props => {
    const isInRouter = useInRouterContext();
    const wrapper = useMemo(() => <BrowserRouter>{props.children}</BrowserRouter>, [props.children]);
    return isInRouter ? <>{props.children}</> : wrapper;
};

const AppComponent = props => {
    return (
        <React.StrictMode>
            <ReduxEnabled>
                <RouterEnabled>{props.children}</RouterEnabled>
            </ReduxEnabled>
        </React.StrictMode>
    );
};

export default AppComponent;
