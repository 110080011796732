import { produce } from 'immer';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { Address } from '../../../../../Shared/js/@types/Address';
import SearchAddressActions from '../../../../../Shared/React/js/actions/SearchAddressActions';
import { AddressSearchHandle } from '../../../../../Shared/React/js/components/addressSearch/AddressAutocomplete';
import { AddressSearchProps } from '../../../../../Shared/React/js/components/addressSearch/AddressSearch';
import ResponsiveLayout from '../../../../../Shared/React/js/components/ResponsiveLayout';
import { ScreenSize } from '../../../../../Shared/React/js/utils/Hooks';
import * as Actions from '../../actions/BroadbandHomeActions';
import ResultsAdaptor from '../../adaptors/BroadbandResultsAdaptor';
import type { Props } from './@types/Filters';
import FiltersLg from './FiltersLg';
import FiltersSm from './FiltersSm';
import FiltersXs from './FiltersXs';

interface AddressSearchResult {
    isError: boolean;
    isNotFound: boolean;
    address: Address;
}

const Filters = (props: Props) => {
    const [inputValue, setInputValue] = useState<string>(props.address?.label || '');

    const [addressSearchResult, setAddressSearchResult] = useState<AddressSearchResult>({
        address: null,
        isNotFound: null,
        isError: null
    });

    const [data, setData] = useState(props.data);
    const [speed, setSpeed] = useState(props.speed);

    const [isSearchInitiated, setIsSearchInitiated] = useState<boolean>(false);

    const addressSearchDesktopRef = useRef<AddressSearchHandle>(null);
    const addressSearchMobileRef = useRef<AddressSearchHandle>(null);
    const addressSearchTabletRef = useRef<AddressSearchHandle>(null);

    const resultsAdaptor = useRef(new ResultsAdaptor());

    const getCurrentLocation = () => {
        window.setTimeout(() => {
            WhistleOut.getCurrentLocationViaHtml5(e => {
                const address = e.label;
                if (address.length > 1) {
                    setInputValue(address);
                    setAddressSearchResult({
                        address: {
                            label: e.label
                        } as Address,
                        isNotFound: false,
                        isError: false
                    });
                }
            });
        }, 500);
    };

    const handleMount = useCallback(() => {
        SearchAddressActions.invalidateAddressCookie();
        getCurrentLocation();
    }, []);

    const getAddressSearch = () =>
        addressSearchDesktopRef?.current || addressSearchMobileRef?.current || addressSearchTabletRef?.current;

    const handleOnInput = useCallback((value: string) => {
        setInputValue(value);
        setAddressSearchResult({
            address: null,
            isNotFound: false,
            isError: false
        });
    }, []);

    const handleOnAddressChange = useCallback((address: Address) => {
        setInputValue(address?.label);
        setAddressSearchResult({
            address: address,
            isNotFound: false,
            isError: false
        });
    }, []);

    const handleOnAddressEmptyResults = useCallback(() => {
        setIsSearchInitiated(false);
        setAddressSearchResult({
            address: null,
            isNotFound: true,
            isError: false
        });
    }, []);

    const handleOnAddressResponseError = useCallback(() => {
        setIsSearchInitiated(false);
        setAddressSearchResult({
            address: null,
            isNotFound: false,
            isError: true
        });
    }, []);

    const doSearch = useCallback(() => {
        if (inputValue) {
            SearchAddressActions.invalidateAddressCookie(inputValue);
        }

        const address = addressSearchResult.address;
        const coveragePromise = address
            ? resultsAdaptor.current.getCoveragePromise({ common: { address: address } }, props.coverageCheckUrl)
            : null;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const criteria = produce(props.criteria, (draft: any): void => {
            draft.speed = speed;
            draft.data = data;
            draft.common.address = address;
        });

        Actions.search(props.searchUrl, criteria, coveragePromise);
    }, [addressSearchResult.address, data, inputValue, props.coverageCheckUrl, props.criteria, props.searchUrl, speed]);

    const searchLocation = useCallback(
        config => {
            WhistleOut.getCurrentLocation(address => {
                const siteCountry = (config.countryCode || '').toLowerCase();
                const clientCountry = (address.countryCode || '').toLowerCase();
                if (address.label.length > 1 && siteCountry === clientCountry) {
                    setAddressSearchResult({
                        address: {
                            label: address.label
                        } as Address,
                        isError: false,
                        isNotFound: false
                    });
                } else {
                    doSearch();
                }
            }, doSearch);
        },
        [doSearch]
    );

    const isEmptyAddress = useCallback(
        inputValue => {
            return (
                !inputValue ||
                inputValue.trim().length === 0 ||
                // HACK: In IE9 the input value has the placeholder text by default
                // Also use 'props' explicitly, because 'addressSearch' is undefined in IE9 for some reason
                inputValue === props.addressSearchProps.placeholder
            );
        },
        [props.addressSearchProps.placeholder]
    );

    const lookupAddress = useCallback(() => {
        const addressSearch = getAddressSearch();
        addressSearch.selectFirstSuggest();
    }, []);

    const search = useCallback(
        (screenSize: ScreenSize) => {
            console.log({ screenSize });

            setIsSearchInitiated(true);

            if (isEmptyAddress(inputValue)) {
                const config = WhistleOut.getSiteConfiguration();
                if (config.autoDetectLocation) {
                    searchLocation(config);
                    return;
                }

                doSearch();
                return;
            }

            if (addressSearchResult.address) {
                doSearch();
            } else {
                lookupAddress();
            }
        },
        [addressSearchResult.address, doSearch, inputValue, isEmptyAddress, lookupAddress, searchLocation]
    );

    const onAddressInputInit = useCallback(
        (input: HTMLInputElement) => {
            if (!input) {
                return;
            }
            input.value = inputValue || '';
        },
        [inputValue]
    );

    useEffect(() => handleMount(), [handleMount]);

    useEffect(() => SearchAddressActions.setAddressCookie(addressSearchResult.address), [addressSearchResult.address]);

    useEffect(() => {
        if (isSearchInitiated && addressSearchResult.address) {
            doSearch();
        }
    }, [doSearch, isSearchInitiated, addressSearchResult.address]);

    const addressCommonProps: AddressSearchProps = {
        ...props.addressSearchProps,
        current: addressSearchResult.address,
        onInput: handleOnInput,
        onChange: handleOnAddressChange,
        onInitialized: onAddressInputInit,
        onEmptyResults: handleOnAddressEmptyResults,
        onResponseError: handleOnAddressResponseError
    };

    const toolTipText = props.addressSearchProps.tooltipLink;
    const commonProps: Props = {
        ...props,
        isLoading: isSearchInitiated,
        marginCss: (typeof toolTipText === 'string' && toolTipText.length === 0) ? 'mar-b-7' : 'mar-b-0',
        onSearchClick: search,
        speedDataSelectProps: {
            ...props.speedDataSelectProps,
            speed: speed,
            data: data,
            onSpeedChange: setSpeed,
            onDataChange: setData
        }
    };

    return (
        <ResponsiveLayout>
            {{
                desktop: (
                    <FiltersLg
                        {...commonProps}
                        addressSearchProps={{
                            ...addressCommonProps,
                            ref: addressSearchDesktopRef
                        }}
                    />
                ),
                mobile: (
                    <FiltersXs
                        {...commonProps}
                        addressSearchProps={{
                            ...addressCommonProps,
                            ref: addressSearchMobileRef
                        }}
                    />
                ),
                tablet: (
                    <FiltersSm
                        {...commonProps}
                        addressSearchProps={{
                            ...addressCommonProps,
                            ref: addressSearchTabletRef
                        }}
                    />
                )
            }}
        </ResponsiveLayout>
    );
};

export default Filters;
