import React from 'react';

import Spinner from '../../../../../../Shared/React/js/components/Spinner';

// TODO: Extract common template for messaging, i.e. 'plan unavailable' and 'blocked pop-up'

const ErrorPanel = (props: { onRetry(): void; isLoading?: boolean }) => {
    return (
        <div className="row">
            <h4 className="font-feature font-8 font-800 line-height-105 c-gray-darker mar-b-3">
                Sorry, something went wrong :(
            </h4>
            <p className="font-5 mar-0 mar-b-5">Please try again.</p>
            <button
                type="button"
                className="btn btn-primary btn-lg mar-b-5 pad-x-8"
                onClick={props.onRetry}
                disabled={props.isLoading}
            >
                {props.isLoading && <Spinner />}
                Retry
            </button>
        </div>
    );
};

export default ErrorPanel;
