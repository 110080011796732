import React from 'react';

import OptionButton from './OptionButton';

const OptionTextButton = (props: { selected: boolean; text: string; onSelected(selected: boolean): void }) => {
    return (
        <OptionButton selected={props.selected} onSelected={props.onSelected}>
            <span>{props.text}</span>
        </OptionButton>
    );
};

export default OptionTextButton;
