import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import AddressHelper from '../../utils/AddressHelper';
import AddressChangeTracker from '../addressSearch/AddressChangeTracker';

export default class AddressMap extends Component {
    constructor(props) {
        super(props);

        this.id = AddressMap.counter++;
        this.mapWrapperId = `header-map-wrapper-${this.id}`;

        this.state = {
            address: this.props.address,
            marker: null,
            isSmallScreen: this.isSmallScreen()
        };

        this.onAddressChange = this.onAddressChange.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onResizeEnd = this.onResizeEnd.bind(this);
        this.getMapContainer = this.getMapContainer.bind(this);
        this.renderStaticMap = this.renderStaticMap.bind(this);
    }

    componentDidMount() {
        addEventListener('DOMContentLoaded', async () => {
            this.mapboxgl = await import(/* webpackChunkName: "mapbox" */ 'mapbox-gl');
            window.addEventListener('resize', this.onWindowResize);
            this.setView(this.state.address);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate() {
        this.setView(this.state.address);
    }

    onAddressChange(address) {
        if (this.props.ignoreAddressChange || AddressHelper.isEqual(address, this.state.address)) {
            return;
        }

        this.setState({ address: address });
    }

    getMapContainer() {
        return wo$(`#${this.props.mapContainerId}`)[0];
    }

    onWindowResize() {
        if (this.resizeTimer) {
            // HACK: Reset the timeout to let the resize finish
            // See: https://css-tricks.com/snippets/jquery/done-resizing-event/
            clearTimeout(this.resizeTimer);
        }

        this.resizeTimer = setTimeout(this.onResizeEnd, 250);
    }

    isSmallScreen() {
        return window.innerWidth < 992;
    }

    onResizeEnd() {
        const isSmallScreen = this.isSmallScreen();
        if (isSmallScreen === this.state.isSmallScreen) {
            return;
        }

        this.setState({ isSmallScreen: isSmallScreen }, () => {
            if (this.useStaticMap()) {
                return;
            }

            const mapContainer = this.getMapContainer();
            if (isSmallScreen) {
                if (mapContainer.innerHTML) {
                    mapContainer.innerHTML = '';
                    window.dynamicMap = null;

                    this.onMapRemove();
                }
            } else {
                if (!mapContainer.innerHTML) {
                    this.initMap();
                }
            }
        });
    }

    onMapRemove() {
        if (this.props.onMapRemove) {
            this.props.onMapRemove();
        }
    }

    onMapInit() {
        if (this.props.onMapInit) {
            this.props.onMapInit();
        }
    }

    initMap() {
        if (window.dynamicMap || typeof window === 'undefined') {
            return;
        }

        console.log('initialising header map');

        const location = this.getLocation(this.state.address, this.props.countryCode);
        const center = location.center || {};
        const zoom = location.zoom;

        this.setState({
            center: center,
            zoom: zoom
        });

        const mapContainer = (
            <div
                id={this.mapWrapperId}
                style={{
                    width: '100%',
                    height: '147px',
                    zIndex: 0
                }}
                className="style-header-map"
            />
        );

        this.getMapContainer().innerHTML = renderToStaticMarkup(mapContainer);
        this.onMapInit();

        const map = new this.mapboxgl.Map({
            accessToken: this.props.apiKey,
            container: this.mapWrapperId,
            style: `mapbox://styles/${this.props.styleId}`,

            center: center,
            zoom: zoom,

            interactive: false,
            trackResize: true,
            attributionControl: false
        });

        map.addControl(new this.mapboxgl.AttributionControl(), 'bottom-right');

        this.offset(map, center);
        this.setMarker(map, center);

        window.dynamicMap = map;
    }

    clearMarker() {
        if (this.state.marker) {
            this.state.marker.remove();
        }
    }

    setMarker(map, center) {
        if (AddressHelper.isCountry(this.state.address)) {
            return;
        }

        const cdn = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7';
        var markerIconUrl =
            wo$(`#${this.props.mapContainerId}`).data('marker-url') || `${cdn}/images/marker-icon-2x.png`;

        var el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = `url(${markerIconUrl})`;
        el.style.backgroundSize = 'contain';
        el.style.backgroundRepeat = 'no-repeat';
        el.style.width = '27px';
        el.style.height = '41px';

        const marker = new this.mapboxgl.Marker(el, { anchor: 'bottom' })
            .setLngLat([center.lng, center.lat])
            .addTo(map);

        this.setState({ marker: marker });
    }

    setView(address) {
        if (this.useStaticMap()) {
            this.renderStaticMap();
            return;
        }

        if (typeof window === 'undefined' || this.state.isSmallScreen) {
            return;
        }

        const map = window.dynamicMap;
        if (!map) {
            this.initMap();
            return;
        }

        const currentCenter = map.getCenter();
        const location = this.getLocation(address, this.props.countryCode);
        const newCenter = location.center;
        if (currentCenter.lat === newCenter.lat && currentCenter.lng === newCenter.lng) {
            return;
        }

        this.setCenter(map, newCenter, location.zoom);
    }

    setCenter(map, center, zoom) {
        if (JSON.stringify(center) === JSON.stringify(this.state.center)) {
            return;
        }

        this.setState({
            center: center,
            zoom: zoom
        });

        this.clearMarker();

        if (map.getZoom() !== zoom) {
            map.setZoom(zoom);
        }

        const currentCenter = map.getCenter();
        if (currentCenter.lng !== center.lng || currentCenter.lat !== center.lat) {
            map.panTo([center.lng, center.lat]);
        }

        if (AddressHelper.isCountry(this.state.address)) {
            return;
        }

        this.offset(map, center);
        this.setMarker(map, center);
    }

    offset(map, center) {
        const targetLatLng = this.getOffsetLatLng(map, center);
        map.panTo(targetLatLng);
    }

    getOffsetLatLng(map, center) {
        const offset = this.getOffset();

        // See: https://github.com/Leaflet/Leaflet/issues/859
        const targetPoint = map.project(center).sub(new this.mapboxgl.Point(offset.x, offset.y));

        return map.unproject(targetPoint);
    }

    getOffset() {
        if (this.props.getOffset) {
            return this.props.getOffset();
        }

        const wrapperRect = wo$(`#${this.mapWrapperId}`)[0].getBoundingClientRect();
        const centerBetween = this.props.getCenterBoundaries();

        const expected = {
            x: this.getCenter(centerBetween.left, centerBetween.right),
            y: this.getCenter(wrapperRect.top, wrapperRect.bottom)
        };

        const actual = {
            x: this.getCenter(wrapperRect.left, wrapperRect.right),
            y: this.getCenter(wrapperRect.top, wrapperRect.bottom)
        };

        return {
            x: expected.x - actual.x,
            y: expected.y - actual.y
        };
    }

    getCenter(start, end) {
        return start + (end - start) / 2;
    }

    getLocation(address, countryCode) {
        if (address && !AddressHelper.isCountry(address)) {
            return {
                center: {
                    lat: address.latitude,
                    lng: address.longitude
                },
                zoom: this.getZoom(address)
            };
        }

        if (!countryCode) {
            throw new Error('countryCode is undefined');
        }

        // Based on https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-hotelsearch
        const countries = {
            au: {
                center: { lat: -33.1382032, lng: 144.7961969 },
                zoom: 6
            },
            ca: {
                center: { lat: 62, lng: -110.0 },
                zoom: 3
            },
            mx: {
                center: { lat: 23.6, lng: -102.5 },
                zoom: 5
            },
            nz: {
                center: { lat: -40.9, lng: 174.9 },
                zoom: 5
            },
            uk: {
                center: { lat: 52.8, lng: -4.6 },
                zoom: 5
            },
            us: {
                center: { lat: 39.85, lng: -97.8 },
                zoom: 5
            }
        };

        return countries[countryCode] || countries['au'];
    }

    getZoom(address) {
        const zoomLevel = AddressMap.zoomLevel;

        if (!address) {
            return zoomLevel.country;
        }

        if (address.unit) {
            return zoomLevel.unit;
        }

        if (address.streetNumber) {
            return zoomLevel.streetNumber;
        }

        if (address.street) {
            return zoomLevel.street;
        }

        if (address.city) {
            return zoomLevel.city;
        }

        if (address.postcode) {
            return zoomLevel.postcode;
        }

        if (address.state) {
            return zoomLevel.state;
        }

        return zoomLevel.country;
    }

    renderStaticMap() {
        window.dynamicMap = null;
        const backgroundMapUrl = this.props.backgroundMapUrl;
        this.getMapContainer().innerHTML = `
        <div id="${this.mapWrapperId}"
        class="style-header-map style-header-map-flat-us"
        style="width: 100%; height: 147px;background: #acdafd url(${backgroundMapUrl});background-position: 65% 50%;background-repeat: no-repeat;background-size: 500px 460px;position: relative"
        > </div>
        `;
    }

    useStaticMap() {
        return this.props.useStaticMap || AddressHelper.isCountry(this.state.address) || !this.mapboxgl.supported();
    }

    render() {
        return (
            <React.StrictMode>
                <AddressChangeTracker onAddressChange={this.onAddressChange} initialValue={this.state.address} />
            </React.StrictMode>
        );
    }
}

AddressMap.counter = 1;
AddressMap.zoomLevel = {
    country: 6,

    unit: 14,
    streetNumber: 14,
    street: 13,

    postcode: 12,
    city: 12,

    state: 5
};
