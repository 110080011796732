import React, { useCallback } from 'react';

import LinkHandler from './LinkHandler';

function getLink(element: HTMLElement) {
    return element.closest('a') || element.closest('[data-transact-link]').querySelector('a');
}

function showPrivateDataModal() {
    wo$(`#${WhistleOut.privateDataModalId}`).modal('show');
}

function getLinkData(link: HTMLAnchorElement) {
    const dataset = (link.closest<HTMLElement>('[data-result]') || link.closest('[data-transact-link]'))?.dataset;
    return {
        productShortUrl: dataset?.productShortUrl || null,
        supplierShortUrl: dataset?.supplierShortUrl || null
    };
}

export interface DynamicTransactLinkData {
    productShortUrl: string;
    supplierShortUrl: string;
}

const TransactLinkHandler = (props: {
    isDynamicLink(url: string): boolean;
    onClick?(url: string): void;
    onDynamicLinkClick?(data: DynamicTransactLinkData): void;
}) => {
    const onClick = useCallback(
        (e: Event) => {
            const link = getLink(e.currentTarget as HTMLElement);
            if (!link) {
                throw new Error('Link element not found');
            }

            if (props.onClick) {
                props.onClick(link.href);
            }

            if (props.isDynamicLink && !props.isDynamicLink(link.href)) {
                return;
            }

            e.preventDefault();

            if (WhistleOut.getPrivateDataCookieValue()) {
                showPrivateDataModal();
                return;
            }

            if (props.onDynamicLinkClick) {
                const data = getLinkData(link);
                props.onDynamicLinkClick(data);
            }
        },
        [props]
    );

    return <LinkHandler querySelector="[data-transact-link] a, [data-transact-link]" onClick={onClick} />;
};

export default TransactLinkHandler;
