import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/* eslint-disable import/no-named-as-default-member */
const propTypes = {
    name: PropTypes.string.isRequired,
    isActive: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    checkActive: PropTypes.func,
    children: PropTypes.element.isRequired
};
/* eslint-enable */

const TabContent = ({ name, isActive: isActiveProps, children }) => {
    const isActive = useMemo(() => {
        return isActiveProps instanceof Function ? isActiveProps(name) : isActive;
    }, [isActiveProps, name]);

    return (
        <div id={`modal-results-broadband-${name}`} className={ClassNames('tab-pane', { active: isActive })}>
            {children}
        </div>
    );
};

TabContent.propTypes = propTypes;
export default TabContent;
