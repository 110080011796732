import ClassNames from 'classnames';
import React, { Component, createRef } from 'react';

import Select from '../.././../../../../Shared/React/js/components/Select';

export default class BundleTab extends Component {
    constructor(props) {
        super(props);

        this.wrapper = createRef();
        this.onPhoneBundleChange = this.onPhoneBundleChange.bind(this);
        this.onPhoneCallsChange = this.onPhoneCallsChange.bind(this);
        this.onTvBundleChange = this.onTvBundleChange.bind(this);
        this.onTvChannelsChange = this.onTvChannelsChange.bind(this);
        this.onLineRentalBundleChange = this.onLineRentalBundleChange.bind(this);
        this.onMobileBundleChange = this.onMobileBundleChange.bind(this);
    }

    componentDidMount() {
        this.applySelectPickersStyle();
        this.applySelectPickersStyle();
    }

    applySelectPickersStyle() {
        const dom = wo$(this.wrapper.current);
        WhistleOut.applySelectPickersStyle(dom);
    }

    onPhoneBundleChange(value) {
        this.props.updatePhoneBundle(value !== '' ? value === 'true' : null);

        // Reset Phone Calls
        if (value === 'false') {
            this.onPhoneCallsChange([]);
        }
    }

    onPhoneCallsChange(values) {
        this.props.updatePhoneCallsBundle(values);
    }

    onTvBundleChange(value) {
        this.props.updateTvBundle(value !== '' ? value === 'true' : null);

        // Reset TV Channels
        if (value === 'false') {
            this.onTvChannelsChange([]);
        }
    }

    onTvChannelsChange(values) {
        this.props.updateTvChannels(values);
    }

    onLineRentalBundleChange(value) {
        this.props.updateLineRentalBundle(value !== '' ? value === 'true' : null);
    }

    onMobileBundleChange(value) {
        this.props.updateMobileBundle(value !== '' ? value === 'true' : null);
    }

    render() {
        const phoneCallsClass = ClassNames('selectpicker-wide', {
            hidden: this.props.criteria.bundles.homePhone !== true && this.props.criteria.bundles.homePhone !== 'true'
        });

        const tvChannelsClass = ClassNames('selectpicker-wide', {
            hidden: this.props.criteria.bundles.tv !== true && this.props.criteria.bundles.tv !== 'true'
        });

        const phone = (
            <div className="row bor-b-1 pad-y-4">
                <div className="hidden-xs col-sm-5 text-center pad-t-2">
                    {' '}
                    <span className="fa fa-phone-2 font-9" />{' '}
                </div>
                <div className="col-xs-24 col-sm-19 col-md-15">
                    <h5 className="font-6 mar-0 pad-b-4">{this.props.resources.phoneBundle}</h5>
                    <div className="selectpicker-wide pad-b-4">
                        <Select
                            elementId="choose-phone"
                            optionGroups={[{ values: this.props.phoneOptions }]}
                            selectedValues={this.props.criteria.bundles.homePhone}
                            onChange={this.onPhoneBundleChange}
                        />
                    </div>
                    {(() => {
                        if (this.props.showPhoneBundlePreferences) {
                            return (
                                <div className={phoneCallsClass}>
                                    <Select
                                        elementId="choose-phone-calls"
                                        title={this.props.resources.phoneBundlePreferences}
                                        optionGroups={[{ values: this.props.phoneCallsOptions }]}
                                        selectedValues={this.props.criteria.bundles.homePhoneCalls}
                                        onChange={this.onPhoneCallsChange}
                                        multiple
                                    />
                                </div>
                            );
                        }
                    })()}
                </div>
            </div>
        );

        const mobile = this.props.showMobilePhoneBundle ? (
            <div className="row bor-b-1 pad-y-4">
                <div className="hidden-xs col-sm-5 text-center pad-t-2">
                    <span className="fa fa-mobile-2 font-9" />
                </div>
                <div className="col-xs-24 col-sm-19 col-md-15">
                    <h5 className="font-6 mar-0 pad-b-4">{this.props.resources.mobilePhoneBundle}</h5>
                    <div className="selectpicker-wide pad-b-4">
                        <Select
                            elementId="choose-mobile-phone-bundle"
                            optionGroups={[{ values: this.props.mobilePhoneBundleOptions }]}
                            selectedValues={this.props.criteria.bundles.mobilePhone}
                            onChange={this.onMobileBundleChange}
                        />
                    </div>
                </div>
            </div>
        ) : null;

        (() => {
            if (this.props.showTvChannels) {
                return (
                    <div className={tvChannelsClass}>
                        <Select
                            elementId="choose-tv-channels"
                            title={this.props.resources.mustHaveChannels}
                            optionGroups={[
                                {
                                    label: this.props.resources.individualChannels,
                                    values: this.props.individualTvChannels
                                },
                                {
                                    label: this.props.resources.kidsChannels,
                                    values: this.props.kidsTvChannels
                                },
                                {
                                    label: this.props.resources.sportsChannels,
                                    values: this.props.sportsTvChannels
                                }
                            ]}
                            selectedValues={this.props.criteria.bundles.tvChannels}
                            onChange={this.onTvChannelsChange}
                            multiple
                        />
                    </div>
                );
            }
            return null;
        })();

        const tv = (
            <div className="row pad-y-4">
                <div className="hidden-xs col-sm-5 text-center pad-t-2">
                    <span className="fa fa-tv font-9" />
                </div>
                <div className="col-xs-24 col-sm-19 col-md-15">
                    <h5 className="font-6 mar-0 pad-b-4">{this.props.resources.tvBundle}</h5>
                    <div className="selectpicker-wide pad-b-4">
                        <Select
                            elementId="choose-tv"
                            optionGroups={[{ values: this.props.tvOptions }]}
                            selectedValues={this.props.criteria.bundles.tv}
                            onChange={this.onTvBundleChange}
                        />
                    </div>
                    {(() => {
                        if (this.props.showTvChannels) {
                            return (
                                <div className={tvChannelsClass}>
                                    <Select
                                        elementId="choose-tv-channels"
                                        title={this.props.resources.mustHaveChannels}
                                        optionGroups={[
                                            {
                                                label: this.props.resources.individualChannels,
                                                values: this.props.individualTvChannels
                                            },
                                            {
                                                label: this.props.resources.kidsChannels,
                                                values: this.props.kidsTvChannels
                                            },
                                            {
                                                label: this.props.resources.sportsChannels,
                                                values: this.props.sportsTvChannels
                                            }
                                        ]}
                                        selectedValues={this.props.criteria.bundles.tvChannels}
                                        onChange={this.onTvChannelsChange}
                                        multiple
                                    />
                                </div>
                            );
                        }
                        return null;
                    })()}
                </div>
            </div>
        );

        const lineRental = this.props.showLineRental ? (
            <div className="row bor-b-1 pad-y-4">
                <div className="hidden-xs col-sm-5 text-center pad-t-2">
                    <span className="fa fa-line-rental font-9" />
                </div>
                <div className="col-xs-24 col-sm-19 col-md-15">
                    <h5 className="font-6 mar-0 pad-b-4">{this.props.resources.lineRental}</h5>
                    <div className="selectpicker-wide pad-b-4">
                        <Select
                            elementId="choose-line-rental"
                            optionGroups={[{ values: this.props.lineRentalOptions }]}
                            selectedValues={this.props.criteria.bundles.lineRental}
                            onChange={this.onLineRentalBundleChange}
                        />
                    </div>
                </div>
            </div>
        ) : null;

        return (
            <div ref={this.wrapper}>
                {lineRental}
                {phone}
                {mobile}
                {tv}
            </div>
        );
    }
}
