import React from 'react';

import type { SelectDataItem } from '../../../../../Shared/js/@types/SelectDataItem';
import Select from '../../../../../Shared/React/js/components/Select';
import { ScreenSize } from '../../../../../Shared/React/js/utils/Hooks';

export interface Props {
    screenSize?: ScreenSize;
    primaryFilterAsSpeed: boolean;
    className?: string;

    speedDescription: string;
    speedFormat: string;
    speed?: number;
    speedValues: SelectDataItem[];
    onSpeedChange?: (value: number) => void;

    dataDescription: string;
    dataFormat: string;
    data?: number;
    dataValues: SelectDataItem[];
    onDataChange?: (value: number) => void;
}

interface SelectArgs {
    selectedValue: number;
    values: SelectDataItem[];
    onValueChanged: (value: number) => void;
}

const SpeedDataSelect = (props: Props) => {
    const dataWidth = props.screenSize === ScreenSize.Mobile ? '100%' : 'fit';

    let type: string;
    let descirption: string;
    let selectArgs: SelectArgs;

    if (props.primaryFilterAsSpeed) {
        type = 'speed';
        descirption = props.speedDescription;
        selectArgs = {
            selectedValue: props.speed,
            values: props.speedValues,
            onValueChanged: props.onSpeedChange
        };
    } else {
        type = 'data';
        descirption = props.dataDescription;
        selectArgs = {
            selectedValue: props.data,
            values: props.dataValues,
            onValueChanged: props.onDataChange
        };
    }

    return (
        <Select
            key={`${type}Select-${props.screenSize}`}
            selectClassName={props.className}
            optionGroups={[{ label: descirption, values: selectArgs.values }]}
            dataWidth={dataWidth}
            onChange={selectArgs.onValueChanged}
            selectedValues={selectArgs.selectedValue}
        />
    );
};

export default SpeedDataSelect;
