import PropTypes from 'prop-types';
import Rcslider from 'rc-slider';
import React, { useCallback, useEffect, useState } from 'react';

import SliderHandle from './SliderHandle';

const RangeSlider = ({ minValue: minValueProps, maxValue: maxValueProps, data, onValueChanged, onValueUpdating }) => {
    const [minValue, setMinValue] = useState(minValueProps);
    const [maxValue, setMaxValue] = useState(maxValueProps);

    useEffect(() => {
        setMinValue(minValueProps);
    }, [minValueProps]);

    useEffect(() => {
        setMaxValue(maxValueProps);
    }, [maxValueProps]);

    const handleValueChanged = useCallback(
        ([minIndex, maxIndex]) => {
            if (onValueChanged) {
                onValueChanged(data[minIndex], data[maxIndex]);
            }
        },
        [onValueChanged, data]
    );

    const handleValueUpdating = useCallback(
        ([minIndex, maxIndex]) => {
            const min = data[minIndex];
            const max = data[maxIndex];

            setMinValue(min);
            setMaxValue(max);
            if (onValueUpdating) {
                onValueUpdating(min, max);
            }
        },
        [onValueUpdating, data]
    );

    const minIndex = data.indexOf(minValue);
    const maxIndex = data.indexOf(maxValue);

    const range = {
        min: 0,
        max: data.length - 1
    };

    const createHandle = useCallback(p => <SliderHandle {...p} />, []);

    return (
        <React.StrictMode>
            <Rcslider.Range
                min={range.min}
                max={range.max}
                onChange={handleValueUpdating}
                range={true}
                pushable={false}
                value={[minIndex === -1 ? range.min : minIndex, maxIndex === -1 ? range.max : maxIndex]}
                onAfterChange={handleValueChanged}
                step={1}
                tipFormatter={null}
                handle={createHandle}
            />
        </React.StrictMode>
    );
};

/* eslint-disable import/no-named-as-default-member */
RangeSlider.prototype = {
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onValueUpdating: PropTypes.func.isRequired
};
/* eslint-enable */

export default RangeSlider;
