import PropTypes from 'prop-types';
import Rcslider from 'rc-slider';
import React, { useCallback } from 'react';

import SliderHandle from './SliderHandle';

interface props {
    current: number;
    data: Array<number>;
    onValueChanged: (value: number) => void;
    onValueUpdating: (value: number) => void;
}

const Slider = ({ current, data, onValueChanged, onValueUpdating }: props) => {
    const handleValueChanged = useCallback(
        index => {
            if (onValueChanged) {
                onValueChanged(data[index]);
            }
        },
        [data, onValueChanged]
    );

    const handleValueUpdating = useCallback(
        index => {
            if (onValueUpdating) {
                onValueUpdating(data[index]);
            }
        },
        [data, onValueUpdating]
    );

    const range = {
        min: 0,
        max: data.length - 1
    };

    const index = data.indexOf(current);
    const createHandle = useCallback(p => <SliderHandle {...p} />, []);

    return (
        <React.StrictMode>
            <Rcslider
                min={range.min}
                max={range.max}
                onChange={handleValueUpdating}
                value={index}
                onAfterChange={handleValueChanged}
                step={1}
                handle={createHandle}
            />
        </React.StrictMode>
    );
};

/* eslint-disable import/no-named-as-default-member */
Slider.prototype = {
    current: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
    onValueChanged: PropTypes.func.isRequired,
    onValueUpdating: PropTypes.func.isRequired
};
/* eslint-enable */

export default Slider;
