import React, { Component } from 'react';

import HeaderMap from '../../../../../Shared/React/js/components/maps/HeaderMap';

export default class HeaderMapWithStatistics extends Component {

    constructor(props) {
        super(props);

        this.getCenterBoundaries = this.getCenterBoundaries.bind(this);
    }

    getCenterBoundaries() {
        const locationHeaderRect = wo$('#location-header')[0].getBoundingClientRect();
        const statisticsRect = wo$('#header-statistics')[0].getBoundingClientRect();

        return {
            left: locationHeaderRect.right,
            right: statisticsRect.left
        };
    }

    render() {
        return (
            <React.StrictMode>
                <HeaderMap
                    address={this.props.address}
                    countryCode={this.props.countryCode}
                    backgroundMapUrl={this.props.backgroundMapUrl}
                    getCenterBoundaries={this.getCenterBoundaries}
                    ignoreAddressChange={this.props.ignoreAddressChange}
                    mapContainerId="header-map"
                    apiKey={this.props.apiKey}
                    styleId={this.props.styleId}
                    useStaticMap={this.props.useStaticMap}
                />
            </React.StrictMode>
        );
    }
}
