import { generateCommonQueryString } from '../../../../Shared/React/js/utils/CommonQueryString';

// TODO: Try reducing cyclomatic complexity and enable the eslint rule
// eslint-disable-next-line complexity
export function generateQueryString(
    /**@type {{common: import('../../../../Shared/js/@types/SearchCriteriaCommon').SearchCriteriaCommon;}} */ data,
    allAvailableConnectionsSelected,
    /** @type {boolean} */ isEmbed
) {
    const appendQueryParam = WhistleOut.appendQueryParam;
    let queryString = '';

    if (data.location) {
        queryString = appendQueryParam(queryString, 'location=' + data.location);
    }

    if (data.data) {
        queryString = appendQueryParam(queryString, 'data=' + data.data);
    }

    if (data.speed) {
        queryString = appendQueryParam(queryString, 'speed=' + data.speed);
    }

    if (data.maxSpeed) {
        queryString = appendQueryParam(queryString, 'maxspeed=' + data.maxSpeed);
    }

    if (data.maxUploadSpeed) {
        queryString = appendQueryParam(queryString, 'maxuploadspeed=' + data.maxUploadSpeed);
    }

    if (data.customer && data.customer.length > 0) {
        queryString = appendQueryParam(queryString, 'customer=' + data.customer);
    }

    if (parseInt(data.maxUpfront) > -1) {
        queryString = appendQueryParam(queryString, 'maxupfront=' + data.maxUpfront);
    }

    if (data.planType) {
        queryString = appendQueryParam(queryString, 'type=' + data.planType);
    }

    if (data.modemShortUrl && data.modemShortUrl.length > 0) {
        queryString = appendQueryParam(queryString, 'modem=' + data.modemShortUrl);
    }

    if (data.simOnly === true) {
        queryString = appendQueryParam(queryString, 'simonly=true');
    }

    if (
        !allAvailableConnectionsSelected &&
        data.connectionTypes &&
        data.connectionTypes.values &&
        data.connectionTypes.values.length > 0
    ) {
        queryString = appendQueryParam(
            queryString,
            'connection=' + data.connectionTypes.values.slice().sort().join(',')
        );
    }

    if (data.excludeBundles) {
        queryString = appendQueryParam(queryString, 'excludebundles=true');
    }

    if (data.reseller) {
        queryString = appendQueryParam(queryString, 'reseller=' + data.reseller);
    }

    if (data.hideEmptyTabs === true) {
        queryString = appendQueryParam(queryString, 'hideemptytabs=true');
    }

    if (data.hideSingleResultTab === true) {
        queryString = appendQueryParam(queryString, 'hidesingleresulttab=true');
    }

    if (data.peakDataOnly === true) {
        queryString = appendQueryParam(queryString, 'peakdata=true');
    }

    if (data.showFewerResults === false) {
        queryString = appendQueryParam(queryString, 'showlessresults=false');
    }

    if (data.uploadNotCounted === true) {
        queryString = appendQueryParam(queryString, 'uploadnotcounted=true');
    }

    if (data.staticIp === true) {
        queryString = appendQueryParam(queryString, 'staticip=true');
    }

    if (data.prepaidExpiry && data.prepaidExpiry !== 0 && data.prepaidExpiry !== '0') {
        queryString = appendQueryParam(queryString, 'prepaidexpiry=' + data.prepaidExpiry);
    }

    if (data.network && data.network.length > 0) {
        queryString = appendQueryParam(queryString, 'network=' + data.network);
    }

    if (data.includeFiveG === true) {
        queryString = appendQueryParam(queryString, '5g=true');
    }

    // TODO: Fix the violation and enable the rule
    // eslint-disable-next-line eqeqeq
    if (data.state != undefined) {
        queryString = appendQueryParam(queryString, 'state=' + data.state);
    }

    if (data.bundles) {
        if (data.bundles.homePhone !== undefined && data.bundles.homePhone !== null) {
            queryString = appendQueryParam(queryString, 'homephone=' + data.bundles.homePhone);
        }

        if (data.bundles.tv !== undefined && data.bundles.tv !== null) {
            queryString = appendQueryParam(queryString, 'tv=' + data.bundles.tv);
        }

        if (data.bundles.lineRental !== undefined && data.bundles.lineRental !== null) {
            queryString = appendQueryParam(queryString, 'linerental=' + data.bundles.lineRental);
        }

        if (data.bundles.homePhone && data.bundles.homePhoneCalls && data.bundles.homePhoneCalls.length > 0) {
            queryString = appendQueryParam(queryString, 'homephonecalls=' + data.bundles.homePhoneCalls.join(','));
        }

        if (data.bundles.tv && data.bundles.tvChannels && data.bundles.tvChannels.length > 0) {
            queryString = appendQueryParam(queryString, 'channels=' + data.bundles.tvChannels.join(','));
        }

        if (data.bundles.mobilePhone !== undefined && data.bundles.mobilePhone !== null) {
            queryString = appendQueryParam(queryString, 'mobilephone=' + data.bundles.mobilePhone);
        }
    }

    if (data.common) {
        const commonQueryString = generateCommonQueryString(data.common, isEmbed);
        if (commonQueryString.length > 0) {
            queryString = appendQueryParam(queryString, commonQueryString);
        }
    }

    return queryString;
}
