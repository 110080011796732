//ToDo: Move to Shared

export default class ComparisonHelper {
    arraysEqual(arr1, arr2) {
        if (!arr1 && !arr2)
            return true;
        if (!arr1 || !arr2)
            return false;
        if (!arr1 && arr2 && arr2.length > 0)
            return false;
        if (!arr2 && arr1 && arr1.length > 0)
            return false;
        if (arr1.length !== arr2.length)
            return false;
        for (let i = arr1.length; i--;) {
            if (arr1[i] !== arr2[i])
                return false;
        }

        return true;
    }
}
