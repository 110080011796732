import { produce } from 'immer';
import { createElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import type { Address } from '../../../../Shared/js/@types/Address';
import { dispatchAddressChangedEvent } from '../../../../Shared/React/js/components/addressSearch/AddressSearch';
import type { SearchResult } from '../../../js/@types/api/SearchResult';
import type { SearchCriteria } from '../../../js/@types/SearchCriteria';
import type { WidenResultsArgs } from '../../../js/@types/WidenRessultsArgs';
import { actions as searchActions, SearchSliceState } from '../../../js/redux/searchSlice';
import ResultsAdaptor from '../adaptors/BroadbandResultsAdaptor';
import BroadbandAddressSearch from '../components/shared/BroadbandAddressSearch';
import { generateQueryString } from '../utils/BroadbandQueryString';

// TODO: Move all state manipulation into the component
// TODO: Use Redux actions
export default class BroadbandActions {
    props: {
        embeddedMode: boolean;
        nbnConnectionTypes: string[];

        criteria: SearchCriteria;
        defaultCriteria: SearchCriteria;
        coverage: string;
        coverageAddress: Address;
        searchSettings: SearchSliceState['settings'];
    };

    actions: {
        searchSuccess: typeof searchActions.searchSuccess;
        searchError: typeof searchActions.searchError;
        searchRetry: () => void;

        disableMaximumResultLimits: typeof searchActions.disableMaximumResultLimits;
        hideFeaturedResults: typeof searchActions.hideFeaturedResults;
        includeNbnConnections: typeof searchActions.includeNbnConnections;
        updateConnectionTypes: typeof searchActions.updateConnectionTypes;
        updateContractType: typeof searchActions.updateContractType;
        updateCriteria: typeof searchActions.updateCriteria;
        updateData: typeof searchActions.updateData;
        updateModem: typeof searchActions.updateModem;
        updatePlanSummarySupplier: typeof searchActions.updatePlanSummarySupplier;
        updateSort: typeof searchActions.updateSort;
        updateSpeed: typeof searchActions.updateSpeed;
        updateSuppliers: typeof searchActions.updateSuppliers;
        updateTab: typeof searchActions.updateTab;
        showModal: (tab: string) => void;
        widenResults: typeof searchActions.widenResults;
    };

    resultsAdaptor: ResultsAdaptor;

    init() {
        this.resultsAdaptor = new ResultsAdaptor();

        if (typeof window !== 'undefined') {
            this.initHistoryTracker();

            if (this.props.coverage) {
                WhistleOut.setCoverageCookie(this.props.coverage);
            }
        }

        this.bindResults();
        if (!this.props.embeddedMode) {
            this.trackPage(false);
        }
    }

    retrySearch() {
        this.actions.updateConnectionTypes([]);
    }

    changeTab = (value: string) => {
        const criteria = produce(this.props.criteria, draft => {
            draft.common.tab = value;
        });

        this.actions.updateTab(value);
        if (!this.props.embeddedMode) {
            const queryString = generateQueryString(criteria, this.props.searchSettings.allConnectionsSelected);
            this.HistoryWrapper.pushQuery(queryString);
        }
    };

    bindResults() {
        this.resultsAdaptor.unbindAll();
        this.resultsAdaptor.bindContractDropdown(
            (contractType: string, hide: boolean) => this.actions.updateContractType({ contractType, hide }),
            (tabName: string) => this.actions.showModal(tabName)
        );
        this.resultsAdaptor.bindCoverageStrip(() => this.actions.includeNbnConnections(this.props.nbnConnectionTypes));
        this.resultsAdaptor.bindCoverageErrorStrip(() => this.retrySearch());
        this.resultsAdaptor.bindSort((expression: string, isAscending: boolean) =>
            this.actions.updateSort({ expression, isAscending })
        );
        this.resultsAdaptor.bindTab((tab: string) => this.changeTab(tab));
        this.resultsAdaptor.bindLoadMore((tab: string, current: string) => this.loadMoreResults(tab, current));
        this.resultsAdaptor.bindWidenResults((options: WidenResultsArgs) => this.actions.widenResults(options));
        this.resultsAdaptor.bindHideFeatureResults(() => this.actions.hideFeaturedResults());
        this.resultsAdaptor.bindDisableMaximumResultLimits(() => this.actions.disableMaximumResultLimits());
        this.resultsAdaptor.bindPlanSummary((supplier: string, maxResults: number) =>
            this.actions.updatePlanSummarySupplier({ supplier, maxResults })
        );
        this.resultsAdaptor.bindModemCarouselLandingPage((modem: string) => this.actions.updateModem(modem));
        this.resultsAdaptor.bindLandingPageCriteria('criteria-data', (data: number) => this.actions.updateData(data));
        this.resultsAdaptor.bindLandingPageCriteria('criteria-speed', (speed: number) =>
            this.actions.updateSpeed(speed)
        );
        this.resultsAdaptor.bindLandingPageCriteria('criteria-supplier', (supplier: string | string[]) =>
            this.actions.updateSuppliers(supplier)
        );
        this.resultsAdaptor.bindCta();
        this.resultsAdaptor.bindNbnAlertModal();
        this.resultsAdaptor.bindSpeedTestResult();
        this.resultsAdaptor.bindCriteriaFilters(this.props.defaultCriteria, (criteria: SearchCriteria) =>
            this.actions.updateCriteria(criteria)
        );
        this.resultsAdaptor.bindFastProviders();

        WhistleOut.notifications.fire('SearchResultsRendered');

        const address = this.props.criteria.common.address || this.props.coverageAddress;
        if (address) {
            this.resultsAdaptor.bindCoverageMap();
        } else {
            this.resultsAdaptor.hideCoverageMapButton();
        }
    }

    loadMoreResults(tab: string, current: string) {
        if (tab !== 'all' && this.props.criteria.common.tab !== tab) return;

        let queryString = generateQueryString(this.props.criteria);
        if (queryString.length > 0) {
            queryString = `${queryString}&current=${current}`;
        } else {
            queryString = `current=${current}`;
        }

        const url = this.props.searchSettings.pagedResultsUrl;
        const fullUrl = `${url}?${queryString}`;

        wo$.ajax({
            type: 'GET',
            url: fullUrl,
            success: (results: string) => {
                this.resultsAdaptor.appendLoadMoreResults(tab, results);
            }
        });
    }

    goToSearch(baseUrl: string, criteria: SearchCriteria) {
        const url = this.getSearchUrl(baseUrl, criteria);
        location.href = url;
    }

    getSearchUrl(baseUrl: string, criteria: SearchCriteria) {
        const queryString = generateQueryString(criteria, this.props.searchSettings.allConnectionsSelected);
        return queryString.length ? `${baseUrl}?${queryString}` : baseUrl;
    }

    // TODO: Remove this
    search(criteria: SearchCriteria) {
        const { embeddedMode } = this.props;
        const { searchUrl, coverageUrl, suppressCoverageCheck } = this.props.searchSettings;

        if (embeddedMode === true) {
            this.searchPageRefresh(criteria, searchUrl, suppressCoverageCheck, coverageUrl);
        }
    }

    searchPageRefresh(
        initialCriteria: SearchCriteria,
        searchUrl: string,
        suppressCoverageCheck: boolean,
        coverageUrl: string
    ) {
        WhistleOut.startProgress('#search-results-partial', 0.1, 50);

        //TODO: Set other criteria that should be reset when navigating to full results from embedded mode
        const criteria = produce(initialCriteria, p => {
            p.common.campaignGroup = null;

            p.common.results = p.common.results || ({} as SearchCriteria['common']['results']);
            p.common.results.hideEmptyTabs = false;
            p.common.results.hideTabsHeaderHeading = false;
            p.common.results.showAllResults = false;
            p.common.results.hideSort = false;
            p.common.results.hideShare = false;
            p.common.results.hideWidenResults = false;
            p.common.results.showRowSorts = true;
            p.common.results.showRowFilters = true;
            p.common.results.maximumNumberOfResults = null;
            p.common.results.hideCoverage = false;
            p.common.results.productLabel = null;
            p.common.results.pageSize = null;
        });

        if (criteria.common.address && suppressCoverageCheck === false) {
            this.resultsAdaptor
                .getCoveragePromise(criteria, coverageUrl)
                .then((connectionTypes: { shortUrl: string }[]) => {
                    const updatedCriteria = produce(criteria, draft => {
                        if (connectionTypes && connectionTypes.length) {
                            draft.connectionTypes.values = connectionTypes.map(t => t.shortUrl);
                        }
                    });
                    
                    this.goToSearch(searchUrl, updatedCriteria);
                });
            return;
        }

        if (!criteria.common.address || !criteria.common.address.state) {
            WhistleOut.getCurrentLocation(
                e => {
                    const updatedCriteria = produce(criteria, draft => {
                        draft.common.address = { label: e.label } as Address;
                    });
                    this.goToSearch(searchUrl, updatedCriteria);
                },
                () => this.goToSearch(searchUrl, criteria)
            );
            return;
        }

        this.goToSearch(searchUrl, criteria);
    }

    onAjaxSearchSuccess(result: SearchResult) {
        const $results = wo$('#results-container');
        const $addressBar = wo$('[data-search-address] > div');
        const $fastProviders = wo$('#fast-providers');
        if ($addressBar.length > 0) {
            unmountComponentAtNode($addressBar[0]);
        }
        $results.html(result.html);
        $fastProviders.html(result.fastProvidersHtml);

        WhistleOut.setCoverageCookie(result.coverage);
        WhistleOut.applyPopover($results);

        const nodeToAttach = $results.find('[data-search-address]');
        if (nodeToAttach.length > 0) {
            render(
                createElement(BroadbandAddressSearch, nodeToAttach.data('search-address')),
                nodeToAttach.find('div')[0]
            );
        }

        this.bindResults();
        WhistleOut.getAds('Broadband');

        wo$(window).resize();

        dispatchAddressChangedEvent({
            sender: this,
            address: result.criteria.common.address
        });

        if (!this.props.embeddedMode) {
            const resultQueryString = generateQueryString(result.criteria, result.allAvailableConnectionsSelected);
            this.HistoryWrapper.pushQuery(resultQueryString);
        }

        this.actions.searchSuccess(result);
        this.trackPage(true);
    }

    onAjaxSearchError() {
        this.actions.searchError();

        const el = wo$('#modal-error');
        el.modal('show');
    }

    initHistoryTracker() {
        WhistleOut.initHistoryTracker(this);
    }

    trackPage(isAjax: boolean, doNotRetry?: boolean) {
        if (WhistleOut.getAnalyticsClientSideData() == null) {
            if (doNotRetry === true) {
                return;
            }
            setTimeout(() => this.trackPage(isAjax, true), 500);
        } else {
            const resultCount = wo$('#search-results-partial').data('result-count');
            const values = [];
            const criteria = this.props.criteria;
            let currentSupplierId = null;
            if (
                criteria.common.currentSupplierShortUrl &&
                criteria.common.currentSupplierShortUrl.length &&
                criteria.common.currentSupplierShortUrl !== '-None-'
            ) {
                currentSupplierId = this.props.searchSettings.suppliers.allItems.filter(
                    i => i.shortUrl === criteria.common.currentSupplierShortUrl
                )[0].id;
            }
            values.push(currentSupplierId);
            values.push(criteria.common.minimumSpend);
            values.push(criteria.common.maximumSpend);
            values.push(criteria.data);
            values.push(criteria.common.includeOffersWithCampaignOnly);
            values.push(criteria.common.resultsSortExpression);
            values.push(criteria.common.resultsSortAscending);
            values.push(resultCount);
            if (criteria.common.address) {
                values.push(criteria.common.address.placeId);
                values.push(
                    criteria.common.address.label
                        ? criteria.common.address.label.replace('|', '')
                        : criteria.common.address.label
                );
                values.push(criteria.common.address.state ? criteria.common.address.state.shortName : null);
                values.push(criteria.common.address.state ? criteria.common.address.state.longName : null);
                values.push(criteria.common.address.city ? criteria.common.address.city.longName : null);
                values.push(criteria.common.address.postcode ? criteria.common.address.postcode.longName : null);
            } else {
                values.push(null);
                values.push(null);
                values.push(null);
                values.push(null);
                values.push(null);
                values.push(null);
            }
            values.push(criteria.modemShortUrl);
            values.push(criteria.speed);
            const valuesString = values.join('|');
            WhistleOut.trackPageView(null, valuesString, isAjax);

            if (isAjax) {
                const value = WhistleOut.getRemarketingData();
                if (value) {
                    value.data.postcode =
                        criteria.common.address && criteria.common.address.postcode
                            ? criteria.common.address.postcode.longName
                            : null;
                    value.data.data = criteria.data;
                    value.data.speed = criteria.speed;
                    value.data.supplier = criteria.common.suppliers.values.join();
                    value.data.connectionType = criteria.connectionTypes.values.join();
                    value.data.bundles = criteria.bundles.selectedTypes.join();
                    WhistleOut.remarketing(value);
                }
            }
        }
    }
}
