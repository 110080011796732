import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import format from 'string-format';

import type { KeyValue } from '../../../../js/@types/KeyValue';
import Slider from './Slider';

export interface Props {
    current: number;
    anyLabel: string;
    fromLabel: string;
    values: KeyValue[];
    containerClassName?: string;
    sliderClassName?: string;
    labelTemplate?: (content: string | JSX.Element) => JSX.Element;
    onValueChanged: (value: number) => void;
    onValueUpdating?: (value: number) => void;
}

const SliderWithLabel = ({
    //
    current,
    sliderClassName = 'rc-slider-container pad-r-5-md pad-r-5-lg pad-x-4-xs pad-t-4',
    ...props
}: Props) => {
    const [value, setValue] = useState(current);

    useEffect(() => {
        setValue(current);
    }, [current]);

    const handleValueUpdating = useCallback(
        value => {
            setValue(value);
            if (props.onValueUpdating) {
                props.onValueUpdating(value);
            }
        },
        [props]
    );

    const getItem = useCallback((value: number) => {
        return props.values.find(v => v.key === value);
    }, [props.values]);

    const labelText =
        value === 0 ? (
            props.anyLabel
        ) : value === -1 ? (
            getItem(value)?.value
        ) : (
            <span
                dangerouslySetInnerHTML={{
                    __html: format(props.fromLabel, getItem(value)?.value)
                }}
            />
        );

    return (
        <div className={props.containerClassName}>
            <div>
                {props.labelTemplate ? (
                    props.labelTemplate(labelText)
                ) : (
                    <div className="font-6 font-5-md font-700">{labelText}</div>
                )}
            </div>
            <div className={sliderClassName}>
                <Slider
                    data={props.values.map(a => a.key)}
                    current={value}
                    onValueChanged={props.onValueChanged}
                    onValueUpdating={handleValueUpdating}
                />
            </div>
        </div>
    );
};

// TODO: Consider using babel-plugin-typescript-to-proptypes to generate PropTypes
/* eslint-disable import/no-named-as-default-member */
Slider.prototype = {
    current: PropTypes.number.isRequired,
    anyLabel: PropTypes.string.isRequired,
    fromLabel: PropTypes.string.isRequired,
    labelTitle: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    containerClassName: PropTypes.string,
    sliderClassName: PropTypes.string,
    labelTemplate: PropTypes.func,
    onValueChanged: PropTypes.func.isRequired
};
/* eslint-enable */

export default SliderWithLabel;
