import React, { Component } from 'react';

import AddressMap from './AddressMap';

export default class HeaderMap extends Component {

    constructor(props) {
        super(props);

        this.getCenterBoundaries = this.getCenterBoundaries.bind(this);
    }

    getCenterBoundaries() {
        if (this.props.getCenterBoundaries) {
            return this.props.getCenterBoundaries();
        }

        const locationHeaderRect = wo$('#location-header')[0].getBoundingClientRect();

        // HACK: .filter() or .map() are not transpiled in this particular case for some reason, giving an error in IE10 (IE9 is fine...)
        const contains = (array, p) => {
            for (let i = 0; i < array.length; i++) {
                const item = array[i].toLowerCase();
                if (p === item) {
                    return true;
                }
            }

            return false;
        };

        var coverageButton = wo$('#coverage-maps-button a')[0];
        if (coverageButton
            && coverageButton.offsetParent !== null
            && !coverageButton.hidden
            && !contains(coverageButton.classList || [], 'hidden')
        ) {

            const coverageButtonRect = coverageButton.getBoundingClientRect();
            return {
                left: locationHeaderRect.right,
                right: coverageButtonRect.left
            };
        }

        const mainContentRect = wo$('#main-content')[0].getBoundingClientRect();
        return {
            left: locationHeaderRect.right,
            right: mainContentRect.right
        };
    }

    render() {
        return (
            <React.StrictMode>
                <AddressMap
                    address={this.props.address}
                    countryCode={this.props.countryCode}
                    backgroundMapUrl={this.props.backgroundMapUrl}
                    getCenterBoundaries={this.getCenterBoundaries}
                    mapContainerId="header-map"
                    apiKey={this.props.apiKey}
                    styleId={this.props.styleId}
                    ignoreAddressChange={this.props.ignoreAddressChange}
                    useStaticMap={this.props.useStaticMap}
                />
            </React.StrictMode>
        );
    }
}
