import defaultTo from 'lodash/defaultTo';
import React, { useCallback, useEffect, useRef } from 'react';

import ModalSpendSlider from './ModalSpendSlider';
import ModalUpfrontSlider from './ModalUpfrontSlider';

const SpendTab = ({
    // TODO: Use only required fields
    criteria,
    spendSlider,
    upfrontSlider,
    resources,
    spendContentFragment,
    updateSpend,
    updateUpfront
}) => {
    const wrapper = useRef(null);

    const onTierButtonClick = useCallback(
        e => {
            const { minSpend, maxSpend } = e.currentTarget.closest('[data-spend]').dataset;
            updateSpend(defaultTo(parseInt(minSpend), null), defaultTo(parseInt(maxSpend), null));
        },
        [updateSpend]
    );

    const handleOnMount = useCallback(() => {
        const dom = wo$(wrapper.current);
        dom.find('[data-spend]').off('click').click(onTierButtonClick);

        return () => dom.find('[data-spend]').off('click');
    }, [onTierButtonClick]);

    useEffect(() => handleOnMount(), [handleOnMount]);

    function createMarkup(html) {
        return { __html: html };
    }

    return (
        <div ref={wrapper}>
            <div className="row pad-t-2 mar-b-6">
                <div className="col-md-24">
                    <h5 className="font-6 mar-0">{resources.spend}</h5>
                </div>
            </div>
            <div className="row pad-t-2 mar-b-3 pad-b-6 sep-b-1">
                <div className="col-md-11">
                    <ModalSpendSlider
                        {...spendSlider}
                        minValue={criteria.common.minimumSpend}
                        maxValue={criteria.common.maximumSpend}
                        updateSpend={updateSpend}
                    />
                </div>
                <div className="col-md-11 col-md-offset-2">
                    <ModalUpfrontSlider
                        {...upfrontSlider}
                        value={criteria.common.maximumUpfront}
                        updateUpfront={updateUpfront}
                    />
                </div>
            </div>
            <div className="row mar-b-5">
                <div dangerouslySetInnerHTML={createMarkup(spendContentFragment)} />
            </div>
        </div>
    );
};

export default SpendTab;
