import ClassNames from 'classnames';
import React, { useCallback } from 'react';

const TabMenuItem = (props: {
    name: string;
    isActive: boolean;
    text: string;
    onSelected: (e: React.MouseEvent) => void;
}) => {
    const onSelected = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            props.onSelected(e);
        },
        [props]
    );

    return (
        <li key={props.name} className={ClassNames('text-center mar-b-2', { active: props.isActive })}>
            <a
                href=""
                data-tab-name={props.name}
                onClick={onSelected}
                className="mar-r-2 pad-x-3 pad-x-1-xs pad-y-2 pad-y-4-xs text-left text-center-xs"
            >
                <strong className="font-4 display-block position-relative">
                    <span>{props.text}</span>
                </strong>
            </a>
        </li>
    );
};

export default TabMenuItem;
