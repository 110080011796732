import { produce } from 'immer';
import React, { useCallback } from 'react';

import SliderWithLabel from '../../../../../../Shared/React/js/components/sliders/SliderWithLabel';
import type { SearchFilters } from '../../../../../js/@types/SearchFilters';

type SliderProps = SearchFilters['speedSlider'];

interface Props extends SliderProps {
    current: number;
    onChanged: (value: number) => void;
}

const SpeedFilter = (props: Props) => {
    const getTooltipProps = useCallback(() => {
        return produce(props.tooltipHtmlAttributes, (draft: { 'data-content': string }) => {
            const template = document.createElement('div');
            template.innerHTML = draft['data-content'];

            // Remove specific speed value related fragment
            const [speedFragment] = Array.prototype.slice
                .call(template.children)
                .filter((p: HTMLElement) => p.innerText.includes(props.speedDescriptionPlaceholder));

            if (speedFragment) {
                template.removeChild(speedFragment);
            }

            // Remove whitespaces
            template.innerHTML = template.innerHTML.trim();

            // Remove first HR
            const hr = template.querySelector('hr');
            if (hr && hr.previousSibling === null) {
                template.removeChild(hr);
            }

            draft['data-content'] = template.innerHTML;
        });
    }, [props.speedDescriptionPlaceholder, props.tooltipHtmlAttributes]);

    const tooltipProps = getTooltipProps();

    return (
        <div className="col-xs-24 col-sm-12">
            <div
                className="font-4 font-3-md font-5-xs c-gray-light line-height-11 mar-b-2-xs hover-bg"
                {...tooltipProps}
            >
                {props.speedLabel}{' '}
                <span>
                    <i className="fa fa-info-circle"></i>
                </span>
            </div>
            <SliderWithLabel
                current={props.current}
                anyLabel={props.anySpeedLabel}
                fromLabel={props.fromLabel}
                values={props.values}
                onValueChanged={props.onChanged}
            />
        </div>
    );
};

export default SpeedFilter;
