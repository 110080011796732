import React, { Component, createRef } from 'react';

import ConnectionType from './ConnectionType';

export default class ConnectionTab extends Component {

    constructor(props) {
        super(props);

        this.allShortUrls = [
            ...this.props.connectionTypeTiers.tier1ConnectionTypes,
            ...this.props.connectionTypeTiers.tier2ConnectionTypes,
            ...this.props.connectionTypeTiers.tier3ConnectionTypes
        ].map(p => p.shortUrl);

        const shortUrls = (props.criteria.connectionTypes || {}).values || [];
        this.state = {
            allUnselected: false,
            selectedShortUrls: shortUrls,
            isAllSelected: shortUrls.length === 0 ? true : false
        };

        this.wrapper = createRef();
        this.renderConnectionType = this.renderConnectionType.bind(this);
        this.handleConnectionTypeValueChanged = this.handleConnectionTypeValueChanged.bind(this);
        this.toggleConnections = this.toggleConnections.bind(this);
        this.toggleTier1 = this.toggleTier1.bind(this);
        this.toggleTier2 = this.toggleTier2.bind(this);
        this.toggleTier3 = this.toggleTier3.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
    }


    isChecked(connectionType) {
        return this.state.selectedShortUrls.length === 0 && !this.state.allUnselected
            || connectionType && connectionType.shortUrl && this.state.selectedShortUrls.includes(connectionType.shortUrl);
    }

    handleConnectionTypeValueChanged(connectionType, checked) {
        console.log('handleConnectionTypeValueChanged', {
            item: connectionType.title,
            checked: checked
        });

        if (!connectionType || !connectionType.shortUrl) {
            const error = new Error('connectionType or the short url is undefined');
            error.data = connectionType;
            throw error;
        }

        if (checked) {
            this.AddItem(connectionType);
        } else {
            this.RemoveItem(connectionType);
        }
    }

    toggleConnections(connections) {
        let anyOneUnChecked = false;
        connections.forEach((connection) => {
            if (!this.isChecked(connection)) {
                anyOneUnChecked = true;
            }
        });

        if (anyOneUnChecked) {
            this.AddItems(connections);
        } else {
            this.RemoveItems(connections);
        }
    }

    updateConnectionTypes(allUnselected, selectedShortUrls) {
        const selected = selectedShortUrls.length !== this.allShortUrls.length
            ? selectedShortUrls
            : [];

        const isAllSelected = !allUnselected && selected.length===0;
        this.setState({
            allUnselected: allUnselected,
            selectedShortUrls: selected,
            isAllSelected: isAllSelected
        });
        this.props.updateConnectionTypes(selected);
    }

    AddItem(connectionType) {
        if (this.isChecked(connectionType)) {
            return;
        }

        let newValue = [...this.state.selectedShortUrls];
        newValue.push(connectionType.shortUrl);

        this.updateConnectionTypes(false, newValue);
    }

    AddItems(connectionTypes) {
        let newValue = [...this.state.selectedShortUrls];
        connectionTypes.forEach(t => {
            if (wo$.inArray(t.shortUrl, newValue) === -1) {
                newValue.push(t.shortUrl);
            }
        });

        this.updateConnectionTypes(false, newValue);
    }

    RemoveItem(connectionType) {
        if (!this.isChecked(connectionType)) {
            return;
        }

        // Removing last connection type
        if (this.state.selectedShortUrls.length === 1) {
            this.unSelectAll();
            return;
        }

        const current = this.state.selectedShortUrls.length === 0
            ? this.allShortUrls
            : this.state.selectedShortUrls;

        this.updateConnectionTypes(
            false,
            current.filter(p => p !== connectionType.shortUrl)
        );
    }

    RemoveItems(connectionTypes) {
        const current = this.state.selectedShortUrls.length === 0
            ? this.allShortUrls
            : this.state.selectedShortUrls;

        let newValue = [...current];
        connectionTypes.forEach(t => {
            const index = newValue.indexOf(t.shortUrl);
            if (index > -1) {
                newValue.splice(index, 1);
            }
        });

        if (newValue.length === 0) {
            this.unSelectAll();
            return;
        }

        this.updateConnectionTypes(false, newValue);
    }

    onSelectAllChange() {
        if(!this.state.isAllSelected){
            this.selectAll();
        }else{
            this.unSelectAll();
        }   
    }
    selectAll() {
        this.updateConnectionTypes(false, []);
    }

    unSelectAll() {
        this.updateConnectionTypes(true, []);
    }

    renderConnectionType(connectionType) {
        const disabled =
            this.props.unavailableConnectionTypes &&
            this.props.unavailableConnectionTypes.length &&
            this.props.unavailableConnectionTypes.findIndex(c => connectionType.shortUrl === c.shortUrl) > -1;

        const checked = disabled === true
            ? false
            : this.isChecked(connectionType);

        return (
            <ConnectionType
                key={connectionType.id}
                item={connectionType}
                disabled={disabled}
                checked={checked}
                onCheckedChanged={this.handleConnectionTypeValueChanged}
            />
        );
    }

    toggleTier1() {
        this.toggleConnections(this.props.connectionTypeTiers.tier1ConnectionTypes);
    }

    toggleTier2() {
        this.toggleConnections(this.props.connectionTypeTiers.tier2ConnectionTypes);
    }

    toggleTier3() {
        this.toggleConnections(this.props.connectionTypeTiers.tier3ConnectionTypes);
    }

    render() {
        function createMarkup(html) { return { __html: html }; }
        return (
            <div ref={this.wrapper}>
                <div className="row pad-t-2 mar-b-3">
                    <div className="col-md-24">
                        <h5 className="font-6 mar-0">
                            {this.props.resources.connectionType}
                        </h5>
                        <div className="checkbox-tick font-4 pad-t-8 pad-b-3">
                            <input
                                type="checkbox"
                                id="connection-selectAll-checkbox"
                                checked={this.state.isAllSelected}
                                onChange={this.onSelectAllChange}
                            />
                            <label
                                className="font-300"
                                htmlFor="connection-selectAll-checkbox"
                            >
                                <span>
                                    {this.props.resources.selectAll}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <hr className="mar-y-2" />
                <div className="row mar-b-5">
                    <div className="col-md-24">
                        <div className="display-flex flex-wrap-wrap justify-content-space-between">
                            <div className="[ col-xs-24 ] [ col-sm-8 ] [ pad-x-5 pad-x-2-xs pad-x-2-sm ] [ text-center ]">
                                <div
                                    className="hover-bg pad-t-3 pad-t-0-xs mar-b-4 text-left-xs"
                                    onClick={this.toggleTier1}
                                >
                                    <h3 className="font-5 font-4-xs mar-0 font-700 display-inline-xs">
                                        {this.props.connectionTypeTiers.tier1Title}
                                    </h3>
                                    <div className="mar-t-2 mar-x-5 hidden-xs">
                                        <img
                                            src="https://r3.whistleout.com.au/public/images/responsive/svg/speed-good.svg"
                                            className="img-responsive center-block width-100-pc"
                                            alt={this.props.connectionTypeTiers.tier1Title}
                                        />
                                    </div>
                                </div>
                                <div className="text-left mar-l-3 mar-l-4-lg mar-b-3">
                                    {
                                        this.props.connectionTypeTiers.tier1ConnectionTypes.map(this.renderConnectionType)
                                    }
                                </div>
                            </div>
                            <div className="[ col-xs-24 ] [ col-sm-8 ] [ bor-l-1 bor-l-0-xs pad-x-5 pad-x-2-xs pad-x-2-sm ] [ text-center ]">
                                <div
                                    className="hover-bg pad-t-3 pad-t-0-xs mar-b-4 text-left-xs"
                                    onClick={this.toggleTier2}
                                >
                                    <h3 className="font-5 font-4-xs mar-0 font-700 display-inline-xs">
                                        {this.props.connectionTypeTiers.tier2Title}
                                    </h3>
                                    <div className="mar-t-2 mar-x-5 hidden-xs">
                                        <img
                                            src="https://r3.whistleout.com.au/public/images/responsive/svg/speed-better.svg"
                                            className="img-responsive center-bloclock width-100-pc"
                                            alt={this.props.connectionTypeTiers.tier2Title} />
                                    </div>
                                </div>
                                <div className="text-left mar-l-3 mar-l-4-lg mar-b-3">
                                    {
                                        this.props.connectionTypeTiers.tier2ConnectionTypes.map(this.renderConnectionType)
                                    }
                                </div>
                            </div>
                            <div className="[ col-xs-24 ] [ col-sm-8 ] [ bor-l-1 bor-l-0-xs pad-x-5 pad-x-2-xs pad-x-2-sm ] [ text-center ]">
                                <div
                                    className="hover-bg pad-t-3 pad-t-0-xs mar-b-4 text-left-xs"
                                    onClick={this.toggleTier3}
                                >
                                    <h3 className="font-5 font-4-xs mar-0 font-700 display-inline-xs">
                                        {this.props.connectionTypeTiers.tier3Title}
                                    </h3>
                                    <div className="mar-t-2 mar-x-5 hidden-xs">
                                        <img
                                            src="https://r3.whistleout.com.au/public/images/responsive/svg/speed-best.svg"
                                            className="img-responsive center-block width-100-pc"
                                            alt={this.props.connectionTypeTiers.tier3Title} />
                                    </div>
                                </div>
                                <div className="text-left mar-l-3 mar-l-4-lg mar-b-3">
                                    {
                                        this.props.connectionTypeTiers.tier3ConnectionTypes.map(this.renderConnectionType)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="mar-y-2" />
                <div dangerouslySetInnerHTML={createMarkup(this.props.connectionTypesContentFragment)} />
            </div>
        );
    }
}
