import React from 'react';

import Spinner from '../../../../../Shared/React/js/components/Spinner';

interface Props {
    className?: string;
    text: string;
    isLoading: boolean;
    onClick: () => void;
}

const SearchButton = (props: Props) => {
    return (
        <button type="button" onClick={props.onClick} className={props.className || 'btn btn-primary'}>
            {props.isLoading ? <Spinner /> : null}
            <b className="display-inline-block pad-x-6">{props.text}</b>
        </button>
    );
};

export default SearchButton;
