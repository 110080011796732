import Update from 'immutability-helper';

import ResultsAdaptor from '../adaptors/BroadbandResultsAdaptor';
import { generateQueryString } from '../utils/BroadbandQueryString';

export default class BroadbandAddressSearchActions {
    constructor(searchUrl, coverageUrl) {
        this.searchUrl = searchUrl;
        this.coverageUrl = coverageUrl;
        this.resultsAdaptor = new ResultsAdaptor();
    }

    onCriteriaChange(criteria) {
        WhistleOut.startProgress('#search-results-partial', 0.1, 50);
        if (criteria.common.address || !this.coverageUrl) {
            const coveragePromise = this.resultsAdaptor.getCoveragePromise(criteria, this.coverageUrl);
            coveragePromise.then((result) => {
                if (result) {
                    criteria.connectionTypes = {};
                    criteria = Update(criteria,
                        {
                            connectionTypes: { values: { $set: result.connectionTypes.map(t => t.shortUrl) } }
                        });
                    if (!result.useDefaultSuppliers) {
                        criteria.common.suppliers = {};
                    }
                }
                this.search(criteria);
            });
        } else {
            this.search(criteria);
        }
    }

    search(criteria) {
        const queryString = generateQueryString(criteria);
        const url = this.searchUrl;
        const fullUrl = queryString.length ? `${url}?${queryString}` : url;
        window.location.assign(fullUrl);
    }
}
