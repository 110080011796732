import React, { useCallback, useEffect } from 'react';

const LinkHandler = (props: { querySelector: string; onClick: (a: Event) => void }) => {
    const onTransactLinkClick = useCallback(
        (e: Event) => {
            if (props.onClick) {
                props.onClick(e);
            }
        },
        [props]
    );

    const getElements = useCallback(() => {
        return document.querySelectorAll(props.querySelector);
    }, [props.querySelector]);

    useEffect(() => {
        getElements().forEach(a => a.addEventListener('click', onTransactLinkClick));

        return () => {
            getElements().forEach(a => a.removeEventListener('click', onTransactLinkClick));
        };
    }, [getElements, onTransactLinkClick, props.querySelector]);

    return <></>;
};

export default LinkHandler;
