import React, { Component, createRef } from 'react';

import ComparisonHelper from '../../../utils/ComparisonHelper';

export default class AdvancedTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modemShortUrl: this.props.criteria.modemShortUrl,
            simOnly: this.props.criteria.simOnly
        };

        this.comparisonHelper = new ComparisonHelper();
        this.wrapper = createRef();
        this.onModemChange = this.onModemChange.bind(this);
    }

    componentDidMount() {
        const dom = wo$(this.wrapper.current);

        this.bindContractTerms(dom);
        this.bindPrepaidExpiry(dom);
        this.bindCurrentlyWith(dom);
        this.initModems(dom);
        this.bindDeals(dom);
        this.bindMegaAdvancedOptions(dom);

        WhistleOut.applySelectPickersStyle(dom);
    }

    componentWillUnmount() {
        const dom = wo$(this.wrapper.current);
        const select = dom.find('#choose-modem');
        select.off('change', this.onModemChange);

        // TODO: Unbind the rest of the handlers
    }

    componentDidUpdate() {
        this.bindControls();
    }

    // TODO: Rewrite this using React approach
    bindControls() {
        const props = this.props;
        const dom = wo$(this.wrapper.current);

        if (!this.comparisonHelper.arraysEqual(props.criteria.common.contractTerms, this.props.criteria.common.contractTerms)) {
            this.bindContractTerms(dom, props.criteria.common.contractTerms);
        }

        if (props.criteria.prepaidExpiry !== this.props.criteria.prepaidExpiry) {
            this.bindPrepaidExpiry(dom, props.criteria.prepaidExpiry);
        }

        if (props.criteria.common.currentSupplierShortUrl !== this.props.criteria.common.currentSupplierShortUrl) {
            this.bindCurrentlyWith(dom, props.criteria.common.currentSupplierShortUrl);
        }

        if (props.criteria.modemShortUrl !== this.props.criteria.modemShortUrl) {
            this.bindModems(dom);
        }

        if (props.criteria.common.includeOffersWithCampaignOnly !== this.props.criteria.common.includeOffersWithCampaignOnly) {
            this.bindDeals(dom, props.criteria.common.includeOffersWithCampaignOnly);
        }

        if (props.criteria.common.maxResultsPerSupplier !== this.props.criteria.common.maxResultsPerSupplier
            || props.criteria.common.showLessResults !== this.props.criteria.common.showLessResults
            || props.criteria.staticIp !== this.props.criteria.staticIp
            || props.criteria.uploadNotCounted !== this.props.criteria.uploadNotCounted) {
            const selectedValues = [];
            if (props.criteria.common.maxResultsPerSupplier === 1 || props.criteria.common.maxResultsPerSupplier === '1') {
                selectedValues.push('single-result');
            }
            if (props.criteria.common.showLessResults !== false && props.criteria.common.showLessResults !== 'false') {
                selectedValues.push('less-results');
            }
            if (props.criteria.staticIp === true || props.criteria.staticIp === 'true') {
                selectedValues.push('static-ip');
            }
            if (props.criteria.uploadNotCounted === true || props.criteria.uploadNotCounted === 'true') {
                selectedValues.push('upload-not-counted');
            }
            this.bindMegaAdvancedOptions(dom, selectedValues);
        }
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    bindContractTerms(dom, newValue) {
        const valueOnly = typeof newValue !== 'undefined';

        const select = dom.find('#choose-contract-terms');
        if (!valueOnly) {
            WhistleOut.applySelectPicker(select);
        }
        if (valueOnly) {
            if (newValue && newValue.length > 0) {
                select.selectpicker('val', newValue);
            }
        } else {
            if (this.props.criteria.common.contractTerms && this.props.criteria.common.contractTerms.length > 0) {
                let selectedValues = this.props.criteria.common.contractTerms;
                if (selectedValues.length === this.props.allContractTerms.length) {
                    selectedValues = [];
                }
                select.selectpicker('val', selectedValues);
            }
        }

        if (!valueOnly) {
            select.on('change', e => {
                const selectedValues = wo$(e.currentTarget).selectpicker('val');
                this.props.updateContractTerms({
                    selected: selectedValues,
                    all: this.props.allContractTerms
                });
            });
        }
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    bindPrepaidExpiry(dom, newValue) {
        const valueOnly = typeof newValue !== 'undefined';

        const select = dom.find('#choose-prepaid-expiry');
        if (!valueOnly) {
            WhistleOut.applySelectPicker(select);
        }
        if (valueOnly) {
            select.selectpicker('val', newValue);
        } else {
            if (this.props.criteria.prepaidExpiry) {
                const selectedValue = this.props.criteria.prepaidExpiry;
                select.selectpicker('val', selectedValue);
            }
        }

        if (!valueOnly) {
            select.on('change', e => {
                const selectedValue = wo$(e.currentTarget).selectpicker('val');
                this.props.updatePrepaidExpiry(selectedValue);
            });
        }
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    bindCurrentlyWith(dom, newValue) {
        const valueOnly = typeof newValue !== 'undefined';

        const select = dom.find('#choose-currently-with');
        if (!valueOnly) {
            WhistleOut.applySelectPicker(select);
        }
        if (valueOnly) {
            select.selectpicker('val', newValue);
        } else {
            if (this.props.criteria.common.currentSupplierShortUrl) {
                const selectedValue = this.props.criteria.common.currentSupplierShortUrl;
                select.selectpicker('val', selectedValue);
            }
        }

        if (!valueOnly) {
            select.on('change', e => {
                const selectedValue = wo$(e.currentTarget).selectpicker('val');
                this.props.updateCurrentSupplier(selectedValue);
            });
        }
    }

    initModems(dom) {
        const select = dom.find('#choose-modem');
        WhistleOut.applySelectPicker(select);
        select.on('change', this.onModemChange);

        this.bindModems(dom);
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    bindModems(dom) {
        const select = dom.find('#choose-modem');
        const newValue = this.state.simOnly ? 'sim-only' : this.state.modemShortUrl;
        if (newValue) {
            select.selectpicker('val', newValue);
        }
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    onModemChange() {
        const selectedValue = wo$('#choose-modem').selectpicker('val');
        const simOnly = selectedValue === 'sim-only';
        const modemShortUrl = simOnly ? 'BYO-Modem' : selectedValue;

        this.setState({
            modemShortUrl: modemShortUrl,
            isSimOnly: simOnly
        }, () => this.props.updateModem({ modemShortUrl, simOnly }));
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    bindDeals(dom, newValue) {
        const valueOnly = typeof newValue !== 'undefined';

        const select = dom.find('#choose-deals');
        if (!valueOnly) {
            WhistleOut.applySelectPicker(select);
        }
        if (valueOnly) {
            select.selectpicker('val', newValue);
        } else {
            if (this.props.criteria.common.includeOffersWithCampaignOnly) {
                select.selectpicker('val', 'true');
            } else {
                select.selectpicker('val', '');
            }
        }

        if (!valueOnly) {
            select.on('change', e => {
                const selectedValue = wo$(e.currentTarget).selectpicker('val');
                this.props.updateIncludeDealsOnly(selectedValue);
            });
        }
    }

    // TODO: Rewrite this using React approach, for example use Select component (see Bundle Tab)
    bindMegaAdvancedOptions(dom, newValue) {
        const valueOnly = typeof newValue !== 'undefined';

        const select = dom.find('#choose-mega-advanced');
        if (!valueOnly) {
            WhistleOut.applySelectPicker(select);
        }
        if (valueOnly) {
            if (newValue && newValue.length > 0) {
                select.selectpicker('val', newValue);
            }
        } else {
            const selectedValues = [];
            if (this.props.criteria.common.maxResultsPerSupplier === 1 || this.props.criteria.common.maxResultsPerSupplier === '1') {
                selectedValues.push('single-result');
            }
            if (this.props.criteria.common.showLessResults !== false && this.props.criteria.common.showLessResults !== 'false') {
                selectedValues.push('less-results');
            }
            if (this.props.criteria.staticIp === true || this.props.criteria.staticIp === 'true') {
                selectedValues.push('static-ip');
            }
            if (this.props.criteria.uploadNotCounted === true || this.props.criteria.uploadNotCounted === 'true') {
                selectedValues.push('upload-not-counted');
            }

            select.selectpicker('val', selectedValues);
        }

        if (!valueOnly) {
            select.on('change', e => {
                const selectedValues = wo$(e.currentTarget).selectpicker('val');
                this.props.updateAdvancedOptions(selectedValues);
            });
        }
    }

    renderMobileBroadbandModems(data) {
        if (data.showMobileModems) {
            return (
                <optgroup label={data.mobileModemsLabel}>
                    {
                        data.mobileModemTypes.map(modem => {
                            return (
                                <option key={modem.key} value={modem.key}>
                                    {modem.value}
                                </option>
                            );
                        })
                    }
                </optgroup>
            );
        }
    }

    render() {
        return (
            <div ref={this.wrapper}>
                <div className="row">
                    <div className="col-md-10">
                        <div className="row pad-b-5">
                            <div className="col-xs-24">
                                <h5 className="font-5">
                                    {this.props.resources.contractLength}
                                </h5>
                                <div className="selectpicker-wide">
                                    <select
                                        id="choose-contract-terms"
                                        tabIndex={-1}
                                        className="selectpicker bs-select-hidden"
                                        multiple
                                        data-selected-text-format="count>1"
                                    >
                                        {
                                            this.props.contractTerms.map(contractTerm => {
                                                return (
                                                    <option key={contractTerm.key} value={contractTerm.key}>
                                                        {contractTerm.value}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row pad-y-5">
                            <div className="col-xs-24">
                                <h5 className="font-5">
                                    {this.props.resources.currentlyWith}
                                </h5>
                                <div className="selectpicker-wide">
                                    <select
                                        id="choose-currently-with"
                                        tabIndex={-1}
                                        className="selectpicker bs-select-hidden"
                                        data-live-search="true"
                                    >
                                        {
                                            this.props.currentlyWithValues.map(currentlyWith => {
                                                return (
                                                    <option key={currentlyWith.key} value={currentlyWith.key}>
                                                        {currentlyWith.value}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row pad-y-5">
                            <div className="col-xs-24">
                                <h5 className="font-5">
                                    {this.props.resources.modemType}
                                </h5>
                                <div className="selectpicker-wide">
                                    <select
                                        id="choose-modem"
                                        tabIndex={-1}
                                        className="selectpicker bs-select-hidden"
                                    >
                                        <option value="">
                                            {this.props.anyModemLabel}
                                        </option>
                                        <optgroup label={this.props.homeModemsLabel}>
                                            {
                                                this.props.homeModemTypes.map(modem => {
                                                    return (
                                                        <option key={modem.key} value={modem.key}>
                                                            {modem.value}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </optgroup>
                                        {
                                            this.renderMobileBroadbandModems(this.props)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-24 col-md-10 col-md-offset-2">
                        <div className="row pad-b-5">
                            <div className="col-xs-24">
                                <h5 className="font-5">
                                    {this.props.resources.prepaidExpiry}
                                </h5>
                                <div className="selectpicker-wide">
                                    <select
                                        id="choose-prepaid-expiry"
                                        tabIndex={-1}
                                        className="selectpicker bs-select-hidden"
                                    >
                                        {
                                            this.props.prepaidExpiryValues.map(expiry => {
                                                return (
                                                    <option key={expiry.key} value={expiry.key}>
                                                        {expiry.value}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row pad-y-5">
                            <div className="col-xs-24">
                                <h5 className="font-5">
                                    {this.props.resources.deals}
                                </h5>
                                <div className="selectpicker-wide">
                                    <select
                                        id="choose-deals"
                                        tabIndex={-1}
                                        className="selectpicker bs-select-hidden"
                                    >
                                        {
                                            this.props.dealsOptions.map(deal => {
                                                return (
                                                    <option key={deal.key} value={deal.key}>
                                                        {deal.value}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row pad-y-5">
                            <div className="col-xs-24">
                                <h5 className="font-5">
                                    {this.props.resources.superMegaAdvanced}
                                </h5>
                                <div className="selectpicker-wide">
                                    <select
                                        id="choose-mega-advanced"
                                        tabIndex={-1}
                                        className="selectpicker bs-select-hidden"
                                        multiple
                                        data-selected-text-format="count>1"
                                        title={this.props.resources.selectOptions}
                                    >
                                        {
                                            this.props.megaAdvancedOptions.map(o => {
                                                return (
                                                    <option key={o.key} value={o.key}>
                                                        {o.value}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
