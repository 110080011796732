import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import type { Address } from '../../../../js/@types/Address';
import AddressHelper from '../../utils/AddressHelper';
import AppComponent from '../AppComponent';
import { AddressChangedEventArgs, onAddressChangedEvent } from './AddressSearch';

interface Props {
    initialValue?: Address;
    onAddressChange: (address: Address) => void;
}

/* eslint-disable import/no-named-as-default-member */
const propTypes = {
    initialValue: PropTypes.any,
    onAddressChange: PropTypes.func
};
/* eslint-enable */

const AddressChangeTrackerComponent = (props: Props) => {
    const onAddressChange = useCallback(
        (value: Address) => {
            if (props.onAddressChange) {
                props.onAddressChange(value);
            }
        },
        [props]
    );

    const onAddressChangedEventHandler = useCallback(
        (e: CustomEvent<AddressChangedEventArgs>) => {
            // TODO: Fix eslint error
            // eslint-disable-next-line @typescript-eslint/no-invalid-this
            if (!e.detail || e.detail.sender === this) {
                return;
            }

            if (AddressHelper.isEqual(props.initialValue, e.detail.address)) {
                return;
            }

            onAddressChange(e.detail.address);
        },
        [onAddressChange, props.initialValue]
    );

    const handleOnMount = useCallback(() => {
        addEventListener(onAddressChangedEvent, onAddressChangedEventHandler);

        // Unmount
        return () => {
            removeEventListener(onAddressChangedEvent, onAddressChangedEventHandler);
        };
    }, [onAddressChangedEventHandler]);

    useEffect(() => handleOnMount(), [handleOnMount]);

    return <></>;
};

const AddressChangeTracker = (props: Props) => {
    return (
        <AppComponent>
            <AddressChangeTrackerComponent {...props} />
        </AppComponent>
    );
};

AddressChangeTracker.propTypes = propTypes;
export default AddressChangeTracker;
