import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useProductLocation = () => {
    const location = useLocation();

    const result = useMemo(() => {
        const [, area, subarea, supplier, connection, product] = location.pathname.split('/');
        const parts = {
            area,
            subarea,
            supplier,
            connection,
            product
        };

        return Object.assign(location, { parts });
    }, [location]);

    return result;
};
