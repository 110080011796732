import React from 'react';

interface Props {
    title: string;
    onClick: () => void;
}

const ShowFiltersButton = (props: Props) => {
    return (
        <div className="col-xs-24 col-sm-5 col-md-4 col-lg-3 filters-3">
            <div className="mar-t-5-xs mar-t-6-sm pad-t-5-sm mar-t-6-md pad-t-2-md mar-t-6-lg pad-t-3-lg">
                <button type="button" className="btn btn-block btn-sm btn-default font-4" onClick={props.onClick}>
                    <span className="fa fa-sliders display-inline-block mar-r-3 align-middle"></span>
                    {props.title}
                </button>
            </div>
        </div>
    );
};

export default ShowFiltersButton;
